import React, {useCallback, useEffect, useState} from 'react';
import './style.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {trackPromise} from "react-promise-tracker";
import guService from "../../../../services/guService";
import Swal from "sweetalert2";


export default function FiltroUsuarios({ filterString, handleModuloSelecionado, moduloSelecionadoComponente }) {

  // const [searchInput, setSearchInput] = useState(null);
  const [listaModulosCliente, setListaModulosCliente] = useState([]);
  const [moduloSelecionado, setModuloSelecionado] = useState(moduloSelecionadoComponente);

  // useEffect(() => {
  //   filterString(searchInput);
  // }, [searchInput])

  useEffect(() => {
    trackPromise(
      guService.modulo.listaCliente().then(res =>{
        setListaModulosCliente(res.data);
      }).catch(e =>
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar lista de módulos " + e.response.data,
          icon: 'error'
        })
      )
    )
  }, []);

  const handleChangeModulo = useCallback(e => {
    handleModuloSelecionado(e.target.value);
    setModuloSelecionado(e.target.value);
  }, [handleModuloSelecionado]);

  useEffect(() => {
    setModuloSelecionado(moduloSelecionadoComponente);
  }, [moduloSelecionadoComponente])



  return (
    <div className='usuarios-filtros'>
      <div className='filtro-input'>
        <select className='input-filtro-nome'value={moduloSelecionado} name="modulo-cliente" onChange={handleChangeModulo}>
          <option value="">Selecione um Módulo</option>
          {listaModulosCliente.map(modulo => <option value={modulo.id} key={modulo.id}>{modulo.nome}</option>)}
        </select>
        <input className='input-filtro-nome' placeholder='Nome'/>
        <input className='input-filtro-nome' style={{marginLeft: '20px'}} placeholder='Email'/>

      </div>
      <button className='botao-buscar-filtro'>
        <FontAwesomeIcon icon={faSearch} />
        Buscar
      </button>
    </div>
  );
}

import React, {useMemo} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function SeletorModuloToggle({isOpen, handleChangeIsOpen}) {
  const icon = useMemo(() => {
    return isOpen ? faArrowRight : faArrowLeft;
  }, [isOpen])

  return (
    <button
      className='botao-abrir-modal-lateral'
      onClick={e => {e.preventDefault(); handleChangeIsOpen(!isOpen) }}
    >
      <FontAwesomeIcon icon={icon} color='black' />
    </button>
  );
}

import React, { useEffect, useMemo, useState } from 'react'
import { faCross, faDice, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';
import CriarDesenvolvedor from './CriarDesenvolvedor';
import AtribuirDesenvolvedor from './AtribuirDesenvolvedor';
import DesenvolvedoresService from '../../../services/desenvolvedorService';
import { DommusTabs } from '../../DommusTabs';
import './index.css';

export default function CadastroDesenvolvedorAPI({ tipo, dev, fecharModal }) {
    const idUsuario = localStorage.getItem('@DommusGU-idUsuario');
    const idCliente = localStorage.getItem('@DommusGU-idCliente');
    const [selectedKey, setSelectedKey] = useState(0);
    const [desenvolvedor, setDesenvolvedor] = useState([]);
    const [atribuicao, setAtribuicao] = useState([]);
 

    function salvarDesenvolvedor() {
        let objDesenvolvedor = {
            "id": desenvolvedor.id,
            "nome_desenvolvedor": desenvolvedor.nome_desenvolvedor,
            "frase_chave": desenvolvedor.chave,
            "cadastrado_por": idUsuario,
        }

        if (objDesenvolvedor.frase_chave?.length > 0 && objDesenvolvedor.nome_desenvolvedor?.length > 0) {
            trackPromise(DesenvolvedoresService.salvar(objDesenvolvedor))
                .then((response) => {
                    handleFecharModal()
                }).catch(error => {
                    Swal.fire('Ooops...', 'Houve um ao salvar o desenvolvedor.', 'error')
                })
        } else {
            Swal.fire(
                'Ooops...',
                'Campo obrigatório não foi preenchido.',
                'error')
        }
    }

    function atualizarDesenvolvedor(desenvolvedor) {
        let objDesenvolvedor = {
            "id": desenvolvedor.id,
            "nome_desenvolvedor": desenvolvedor.nome_desenvolvedor,
            "frase_chave": desenvolvedor.chave,
        }

        if (objDesenvolvedor.frase_chave?.length > 0 && objDesenvolvedor.nome_desenvolvedor?.length > 0) {
            trackPromise(DesenvolvedoresService.atualizar(objDesenvolvedor))
                .then((response) => {
                    handleFecharModal()
                }).catch(error => {
                    Swal.fire('Ooops...', 'Houve um ao atualizar o desenvolvedor.', 'error')
                })
        } else {
            Swal.fire(
                'Ooops...',
                'Campo obrigatório não foi preenchido.',
                'error')
        }

    }

    function atribuirDesenvolvedor(atribuicao) {

        if (atribuicao?.desenvolvedor && atribuicao?.modulos?.length > 0) {
            let atribuir = {
                desenvolvedor: atribuicao?.desenvolvedor?.value,
                cliente: idCliente,
                modulos: atribuicao?.modulos?.map(modulo => {
                    return modulo.value;
                })
            }
            trackPromise(DesenvolvedoresService.atribuirDesenvolvedor(atribuir))
                .then((response) => {
                    handleFecharModal()
                }).catch(error => {
                    Swal.fire('Ooops...', 'Houve um erro ao atribuir o Desenvolvedor.', 'error')
                })
        } else {
            Swal.fire('Ooops...',
                'Parece que há um campo não preenchido!.',
                'error')

        }
    }

    const tabsSections = useMemo(() => {
        if (tipo == "Criar") {
            return (
                [{
                    id: 1,
                    title: tipo,
                    content: <CriarDesenvolvedor
                        dev={dev}
                        handleFecharModal={handleFecharModal}
                        infoDev={desenvolvedor}
                        setInfoDev={setDesenvolvedor}
                        tipo={tipo} />
                },
                {
                    id: 2,
                    title: "Atribuir",
                    content: <AtribuirDesenvolvedor setValorAtribuicao={setAtribuicao} />
                }
                ]
            )
        } else {
            return (
                [{
                    id: 1,
                    title: tipo,
                    content: <CriarDesenvolvedor
                        dev={dev}
                        handleFecharModal={handleFecharModal}
                        infoDev={desenvolvedor}
                        setInfoDev={setDesenvolvedor}
                        tipo={tipo} />
                }
                ]
            )
        }

    }, [tipo])


    function handleFecharModal() {
        fecharModal(false)
    }


    return (
        <>
            <ReactTooltip />
            <Row>
                <Col>
                    <Container fluid>
                        <section className="questionarios-section">
                            <DommusTabs tabs={tabsSections} getActiveTabIndex={(index) => { setSelectedKey(index) }} goToTab={0} />
                        </section>
                    </Container>
                    <Container className='container-botoes-modal'>
                        {selectedKey == 0 && <> {tipo == "Editar" ?
                            <Button variant="success" className="mt-3 float-right botoes-modal" onClick={(e) => atualizarDesenvolvedor(desenvolvedor)}>
                                Atualizar
                            </Button> :
                            <Button variant="success" className="mt-3 float-right botoes-modal" onClick={(e) => salvarDesenvolvedor(desenvolvedor)}>
                                Salvar
                            </Button>}</>}
                        {selectedKey == 1 &&
                            <Button variant="success" className="mt-3 float-right botoes-modal" onClick={(e) => atribuirDesenvolvedor(atribuicao)}>Atribuir</Button>
                        }
                        <Button variant="danger" className="mt-3 float-right botoes-modal" onClick={(e) => handleFecharModal()}>
                            Fechar
                        </Button>
                    </Container>
                </Col>
            </Row>

        </>
    )
}

import React, {useState} from 'react';
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {trackPromise} from "react-promise-tracker";
import guService from "../../../../services/guService";
import Swal from "sweetalert2";

export default function LinhaUsuario ({clienteUsuario, handleEditarUsuario}) {
  const [ativo, setAtivo] = useState(clienteUsuario.status);

  const handleChangeStatus = value => {
    const data = {
      id_usuario: clienteUsuario.id_usuario,
      status: value,
      id_modulo: null
    }
    trackPromise(
      guService.usuario.alterarStatus(data).then(res => {
        setAtivo(res.data.status);
      }).catch(err =>{
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao alterar o status " + err.response.data,
          icon: 'error'
        });
      })
    )
  };

  return (
    <tr>
      <td>{clienteUsuario.usuario.nome}</td>
      <td>{clienteUsuario.usuario.email}</td>
      <td>
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <label className={'btn ' + (ativo ? 'btn-success' : 'btn-secondary')}>
            <input type="radio" name="options" autoComplete="off" value='Ativo'
                   checked={ativo} onChange={() => handleChangeStatus(true)}/> Ativo
          </label>
          <label id="Inativo" className={'btn ' + (!ativo ? 'btn-success' : 'btn-secondary')}>
            <input type="radio" name="options" autoComplete="off" value='Inativo'
                   checked={!ativo} onChange={() => handleChangeStatus(false)}/> Inativo
          </label>
        </div>
      </td>
      <td>
        <button className='botao-editar-tabela'>
          <Dropdown>
            <Dropdown.Toggle variant="primary">
              <FontAwesomeIcon icon={faPencilAlt}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  handleEditarUsuario(clienteUsuario.usuario)
                }}
                style={{color: '#0A71b2'}}>
                <FontAwesomeIcon icon={faPencilAlt} style={{marginRight: '30px'}}/>
                Editar
              </Dropdown.Item>
              {/*<Dropdown.Item
                as="button"
                onClick={() => {
                  console.log('LIXO')
                }}
                style={{color: '#F44336'}}>
                <FontAwesomeIcon icon={faTrash} style={{marginRight: '30px'}}/>
                Excluir
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  console.log('ACESSAR')
                }}
                style={{color: '#0A71b2'}}>
                <FontAwesomeIcon icon={faEye} style={{marginRight: '30px'}}/>
                Acessar
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  console.log('LEADS')
                }}
                style={{color: '#0A71b2'}}>
                <FontAwesomeIcon icon={faClock} style={{marginRight: '30px'}}/>
                Leads Agendados
              </Dropdown.Item>*/}
            </Dropdown.Menu>
          </Dropdown>
        </button>
      </td>
    </tr>
  );
}

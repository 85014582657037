import React from 'react';
import {useForm} from 'react-hook-form';
import guService from '../../services/guService';
import '../Login/index.css';
import logo from '../../assets/logo.svg';
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory} from "react-router-dom";


export default function RecuperarSenha({ history }) {
  const {register, handleSubmit, formState:{errors}} = useForm()
  const location = useHistory();

  
  
  const onSubmit = (data) => {
    console.log(data.email);
    guService.auth.recuperarSenha(data.email).then(res => {
      Swal.fire({
        titleText: "Verifique sua caixa de emails",
        text: "Se o email estiver cadastrado em nosso sistema, você receberá um link de recuperação de senha.",
        icon: 'success'
      }).then(() => {
        location.push('/login')
      })
    }).catch(error => {
      if(error.response.status == 406){
        Swal.fire({
            titleText: "Erro",
            text: `Ooops... ${error.response.data.message} Favor verificar as informações!`,
            icon: 'error'
        });
      }else{
        Swal.fire({
          titleText: "Erro",
            text: "Houve um problema ao tentar recuperar a senha.",
            icon: 'error'
      });
      }
    })
  }

  return (<div className="login">
    <div className="imagemLogin">
      <div className="imagemFundoLogin">
        <div className="logo-dommus">
          <img src={logo} alt="Dommus" />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="logo-dommus mobile">
        <img src={logo} alt="Dommus" />
      </div>
      <div className='faca-login'>Recuperação de Senha:</div>
      <div className="content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">E-MAIL</label>
          <input
            type="email"
            id='email'
            placeholder="Digite seu e-mail" 
            name='email'
            ref={register({required: true})}
          />
          {errors.email && <span className="error" style={{fontSize:"12px"}}>Informe um email</span>}
          <div className="buttons">
            <button className="btn btn-submit" type="submit">Recuperar</button>
          </div>
          <div style={{marginBottom: '20px', textAlign: 'left'}} className="forgot-password">
            <Link to='/login'>
              <FontAwesomeIcon
                data-tip="recuperar senha"
                icon={faChevronLeft}
              /> Voltar para o login
            </Link>
          </div>
        </form>
      </div>

    </div>
  </div>
  )
}

import React, {createContext} from "react";
import { ClienteProvider } from "./ClienteContext";


export const AppContext = createContext({});

export function AppContextProvider ({children}) {

  return (
    <AppContext.Provider value={{}}>
      <ClienteProvider>
        {children}      
      </ClienteProvider>
    </AppContext.Provider>
  )
}

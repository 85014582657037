import React, {useCallback, useEffect, useState} from 'react';

import './style.scss';
import ReactTooltip from "react-tooltip";

export default function DommusCheckboxToggle({label, value, setValue, tooltip, ...rest}) {
    if(value==null) {value=false;}
    const [checked, setChecked] = useState(value);

    if(rest.habilitado === undefined) {
        rest.habilitado=true;
    }

    useEffect(() => {
        setChecked(value);
    }, [value])

    const handleToggleChecked = useCallback((newStatus) => {
        if(!rest.habilitado) {
            return false;
        }
        setChecked(newStatus)
        setValue(newStatus)
    }, [setValue, rest]);



    return (
        <>
            <ReactTooltip/>
            <label className={"form-switch " + (rest.habilitado ? '' : 'disabled')}>
                <input type="checkbox" checked={checked}/>
                <i onClick={() => handleToggleChecked(!checked)} data-tip={tooltip}></i>
                {label}
            </label>
        </>
    )
}

import React, { useState, useContext } from 'react';
import guService from '../../services/guService';
import '../Login/index.css';
import logomarca from '../../assets/logomarca-dommus-mm-login.svg';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faKey} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import { ClienteContext } from '../../context/ClienteContext';
import { isDesktop, isMobile } from 'react-device-detect';
import  appStore from "./../../assets/mobile/apple.svg"
import  playStore from "./../../assets/mobile/google.png"
export default function Login({ history }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [ocultaSenha, setOcultaSenha] = useState(true);
  const {recarregarClientes} = useContext(ClienteContext);

  async function handleSubmit(event) {
    event.preventDefault();
    const loginData = await guService.login({ email, password });

    if (loginData && loginData.valido) {
      if((loginData.clients_count === 1) && (loginData.bloquear_sistema)){
        await Swal.fire({
            titleText: "Atenção",
            text: "Conta Bloqueada. Entre em contato com a nossa equipe,através dos nossos canais de atendimento, para verificar o motivo do bloqueio. Obrigado.",
            icon: 'warning'
        });
        return false;
      }
      guService.setLoginInfo(loginData);
      try{
        document.cookie = "gu_access_token=" + loginData.access_token + "; Domain=dommus.com.br; Secure; Path=/;";
      }catch(error){
        console.log(error)
      }
      let telas = [];

      let arrayTipoTelas = {
        dashboard: [1, 2, 3, 4, 7, 8, 9, 10, 25, 26],
        'permissao-usuario': [1, 2, 3, 4, 7, 8, 9, 10, 25, 26],
        'permissao-tipo': [1, 2, 3, 4, 7, 8, 9, 10, 25, 26],
      };

      for (const [tela, tipos] of Object.entries(arrayTipoTelas)) {
        if (tipos.includes(Number(loginData.id_usuario_tipo))) {
          telas.push(tela)
        }
      }
      guService.setLocalState('telas', telas);

      const usuariosTelaAPI = [1];
      guService.setLocalState('usuarios-tela-api', usuariosTelaAPI);

      const usuariosConfigAPI = [1];
      guService.setLocalState('usuarios-config-api', usuariosConfigAPI);

      const broadcastChannel = new BroadcastChannel('dommus_login_channel');
      broadcastChannel.postMessage({ type: 'login', userLoggedIn: true });

      recarregarClientes();
      history.push('/');
      window.location.reload();
    } else {
      setError(true);
      setErrorMessage(loginData.message);
    }
  }

  function OcultarSenha(){
    if(ocultaSenha === true){
      setOcultaSenha(false)
      document.getElementById('password').type = 'text'
    }else{
      setOcultaSenha(true)
      document.getElementById('password').type = 'password'
    }
  }

  function handleForgotPassword() {

  }


  return (<div className="login">
    <div className="imagemLogin">
      <div className="imagemFundoLogin">
        <div className="logo-dommus">
          <img src={logomarca} alt="Dommus" />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="logo-dommus mobile">
        <img src={logomarca} alt="Dommus" />
      </div>
      {isDesktop &&
        <div className='faca-login'>Seja bem vindo:</div>
      }
      <div className="content">
        <form onSubmit={handleSubmit}>
          {isMobile &&
            <div className='faca-login'>Seja bem vindo:</div>
          }
          <label htmlFor="email">E-MAIL</label>
          <input
            type="email"
            id="email"
            placeholder="Digite seu e-mail"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />

          <div className='label-senha'>
            <label htmlFor="password">SENHA</label>
            <FontAwesomeIcon
              icon={ocultaSenha === true ? faEyeSlash : faEye}
              color={ocultaSenha === true ? '#0a71b2' : '#07476b'}
              onClick={() => {OcultarSenha()}}
              cursor='pointer'
            />
          </div>
          <input
            type="password"
            id="password"
            value={password}
            placeholder="Digite sua senha"
            onChange={event => setPassword(event.target.value)}
          />
          {error ? <p className="error">{errorMessage}</p> : <></>}
          <div className="forgot-password">
            <Link to="/recuperar-senha">
              <FontAwesomeIcon
                data-tip="recuperar senha"
                icon={faKey}
                style={{marginRight: "4px"}}
              />
              Esqueceu a Senha?
            </Link>
          </div>
          <div className="buttons">
            <button className="btn btn-submit" type="submit">Entrar</button>

          </div>

        </form>

        
      </div>
      <div className="container-badges-icons">
        <a href={'https://play.google.com/store/apps/details?id=com.painel_dommus'}>
              <img className="play-store" src={playStore}/>
            </a>
          
      
            <a href={'https://apps.apple.com/br/app/dommus/id6494988134'}>
              <img className="app-store" src={appStore}/>
            </a>  
        </div> 
    </div>
  </div>
  )
}

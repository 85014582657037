import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Col, Form, Row } from "react-bootstrap";
import DommusRequiredSpan from "../../../../../components/DommusRequiredSpan";
import "./style.scss";
import DommusToggle from '../../../../../components/DommusToggle'
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import PermissaoComponent from "../../../../../components/PermissaoComponent";
import guService from "../../../../../services/guService";
import SeletorModulo from "../../../SeletorModulo";
import SeletorModuloToggle from "../../../SeletorModulo/SeletorModuloToggle";
import Swal from "sweetalert2";
import {trackPromise} from "react-promise-tracker";
import {successToast} from "../../../../../components/DommusToast";

export default function ModalPerfil({closeModal, handleModuloSelecionado, moduloSelecionadoComponente, perfilAtual}) {


  const [tiposPerfil, setTiposPerfil] = useState([])
  const [permissao, setPermissao] = useState([]);
  const [tipoPerfilSelecionado, setTipoPerfilSelecionado] = useState(null);
  const [buscaPermissaoInput, setBuscaPermissaoInput] = useState('');
  const [textoBtnSubmit, setTextoBtnSubmit] = useState('Salvar');
  const [seletorOpen, setSeletorOpen] = useState(true);
  const [moduloSelecionado, setModuloSelecionado] = useState(moduloSelecionadoComponente);
  const [perfil, setPerfil] = useState(perfilAtual)
  const [permiteAlterar, setPermiteAlterar] = useState(true)
  const [isEditing, setIsEditing] = useState(false)

  const handleChangeModulo = useCallback(modulo => {
    setModuloSelecionado(modulo)
    handleModuloSelecionado(modulo);
  }, [handleModuloSelecionado]);

  const handleSubmitForm = data => {
    data.id_modulo = moduloSelecionado;
    if(permiteAlterar) {
      if(isEditing) {
        data.id_perfil = perfil.id_perfil;
        data.id_perfil_padrao =  null;
      } else {
        data.id_perfil_padrao = tipoPerfilSelecionado;
      }
      trackPromise(
        guService.permissao.salvarPermissao(data, permissao).then(res => {
          successToast.fire({
            text: 'Perfil salvo com sucesso'
          })
        }).catch(e => {
          Swal.fire({
            titleText: "Erro",
            text: "Houve um problema ao cadastrar o perfil " + e.response.data,
            icon: 'error'
          });
        })
      )
    }
  }

  const handleErroModulo = useCallback(() => {
    setModuloSelecionado(null);
  }, []);

  useEffect(() => {
      setPermiteAlterar(perfil && perfil.perfil_dommus !== 'S');
      setTipoPerfilSelecionado(perfil.id_perfil)
      setIsEditing(Object.keys(perfil).length)
  }, [perfil])


  const handleSelecionaPerfil = useCallback(id_perfil => {
    setTipoPerfilSelecionado(id_perfil);
  }, []);

  useEffect(() => {
    if(!moduloSelecionado) return;
    setTiposPerfil([]);
    trackPromise(
      guService.perfil.listar(moduloSelecionado).then(res => {
        setTiposPerfil(res.data);
      }).catch(e => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar os tipos de perfil  " + e.response.data,
          icon: 'error'
        });
      })
    )
  }, [moduloSelecionado]);

  const formPerfil = useRef();

  const { register, handleSubmit, watch, errors } = useForm();

  return (
    <div id='modulos'>
      <div id='main'>
        <Form className="formCadastro" ref={formPerfil} onSubmit={handleSubmit(handleSubmitForm)}>
          <Row>
            <Form.Group as={Col} className="col-6" controlId="nomePerfil">
              <Form.Label>Usuário<DommusRequiredSpan /></Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome do usuário"
                name="nome"
                disabled={!permiteAlterar}
                defaultValue={perfil.descricao}
                ref={register({required: "Campo Obrigatório."})}/>
              <div className="error" style={{color: 'red'}}>
                <ErrorMessage
                  errors={errors}
                  name="nome"
                />
              </div>
            </Form.Group>


            <Form.Group as={Col} controlId="tipoPerfil">
              <Form.Label>Tipo de perfil<DommusRequiredSpan /></Form.Label>
              {(permiteAlterar && !isEditing) &&
              <Form.Control
                as="select"
                placeholder="Selecione"
                rows={3}
                name="tipo_perfil"
                disabled={!permiteAlterar || isEditing}
                ref={(permiteAlterar && !isEditing) ? register({required: "Campo Obrigatório."}) : null}
                defaultValue={tipoPerfilSelecionado}
                onChange={e => handleSelecionaPerfil(e.target.value)}
              >
                <option value="">Selecione o tipo de perfil</option>
                {tiposPerfil.map(tipoPerfil => {
                  return <option value={tipoPerfil.id_perfil} key={tipoPerfil.id_perfil}>{tipoPerfil.descricao}</option>
                })}
              </Form.Control>
              }
              {(!permiteAlterar || isEditing) ? <Form.Control type="text" value="Tipo Permissao Padrão" disabled/> : ''}
              <div className="error" style={{color: 'red'}}>
                <ErrorMessage
                  errors={errors}
                  name="tipo_perfil"
                />
              </div>
            </Form.Group>
            <Col style={{ flexGrow: 0, marginTop: '2em' }}>
              <SeletorModuloToggle handleChangeIsOpen={() => setSeletorOpen(!seletorOpen)} isOpen={seletorOpen}/>
            </Col>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formNome">
              <Form.Control
                type="text"
                placeholder="Buscar"
                value={buscaPermissaoInput}
                onChange={(event) => setBuscaPermissaoInput(event.target.value)} />
            </Form.Group>
          </Row>
          Permissoes:
          <PermissaoComponent
            permiteAlterar={permiteAlterar}
            buscaString={buscaPermissaoInput}
            handleSetPermissao={permissaoComponente => setPermissao(permissaoComponente)}
            moduloSelecionado={moduloSelecionado}
            tipoPerfilSelecionado={tipoPerfilSelecionado}
            handleErroModulo={handleErroModulo}
          />
          <Row>
            <Form.Group as={Col} controlId="formNome">

            </Form.Group>
          </Row>

          <Row>
            <Col>
              <button className='botao-modal-fechar' onClick={e => {
                e.preventDefault();
                closeModal();
              }}>
                Fechar
              </button>
            </Col>
            <Col style={{ flexGrow: 0 }}>
              <button className={'botao-modal-salvar-usuario' + (moduloSelecionado ? '' : ' disabled')}>
                <FontAwesomeIcon icon={faSave} />
                {textoBtnSubmit}
              </button>
            </Col>
          </Row>
        </Form>
      </div>
      <SeletorModulo isOpen={seletorOpen} handleChangeModulo={handleChangeModulo} moduloSelecionado={moduloSelecionado}/>
    </div>
  );
}


import React, { useState, useCallback } from 'react';
import './style.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import FiltroUsuarios from './FiltroUsuarios';
import ListaUsuarios from './ListaUsuarios';
import ModalDommus from "../../../components/DommusModal";
import NovoUsuarioModal from './ListaUsuarios/NovoUsuarioModal';


export default function PermissaoUsuario() {
  const [openModal, setOpenModal] = useState(false);
  const [moduloSelecionado, setModuloSelecionado] = useState(null);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false)
  }, []);

  const handleAbrirModal = useCallback(() => {
    setOpenModal(true);
  }, []);


  const handleModuloSelecionado = useCallback(id_modulo => {
    setModuloSelecionado(id_modulo)
  }, []);

  const handleEditarUsuario = useCallback(
    usuario => {
      setUsuarioSelecionado(usuario);
      setOpenModal(true);
    },
    [],
  );


  return (
    <>
      <div className='cadastro-usuario'>
        Cadastro de Usuários
        <button className='btn-novo' onClick={handleAbrirModal}>
          <FontAwesomeIcon icon={faUserPlus} />
          NOVO
        </button>
      </div>
      <FiltroUsuarios
        handleModuloSelecionado={handleModuloSelecionado}
        moduloSelecionadoComponente={moduloSelecionado}
      />
      <ListaUsuarios
        moduloSelecionadoComponente={moduloSelecionado}
        handleEditarUsuario={handleEditarUsuario}
      />
      <ModalDommus className="modal-usuarios" size={"hg"}  open={openModal} close={handleCloseModal} title='Usuário'>
        <NovoUsuarioModal
          closeModal={handleCloseModal}
          moduloSelecionadoComponente={moduloSelecionado}
          handleModuloSelecionado={handleModuloSelecionado}
          usuario={usuarioSelecionado}
        />
      </ModalDommus>
    </>
  )
}

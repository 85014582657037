import React, {useEffect, useState} from 'react';
import guService from "../../services/guService";
import Swal from "sweetalert2";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function Validacao() {
  const [dados, setDados] = useState({});
  const [message, setMessage] = useState('Aguarde...');
  const history = useHistory()
  const { slugCliente } = useParams()

  useEffect(() => {
    if(slugCliente) {
      guService.setLocalState('clientePadrao', slugCliente)
    }
    guService.usuario.buscaDadosModulos().then(res => {
      setDados(res.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar a  " + err,
        icon: 'error'
      })
    )
  }, [])


  function acessarModulo() {
    guService.modulo.acessar(dados.modulo_redirecionar).then(res => {
      const {modulo, token} = res.data;
      window.location  = (modulo.uri + token);
    }).catch(error => {
      console.log(error)
      console.log(error.response)
    })
  }


  useEffect(() => {
    if(dados.clients_count || dados.modules_count) {
      return history.push('/dashboard')
    }
    // if(dados.clients_count === 1 && dados.modules_count === 1) {
    //   acessarModulo()
    // }

    if(dados.clients_count === 0) {
      setMessage('você não tem nenhuma incorporadora vinculada, entre em contato com e solicite seu acesso');
    }

    if(dados.clients_count === 1 && dados.modules_count === 0) {
      setMessage('você não tem nenhum módulo vinculado, entre em contato com a incorporadora e solicite seu acesso');
    }


  },[acessarModulo, dados])



  return (<>{message}</>);
}

import React from 'react';
import './App.css';
import './assets/poppins/poppins.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './routes';
import {BrowserRouter} from "react-router-dom";
import DommusPromiseLoading from "./components/DommusLoading/DommusPromiseLoading";
import { AppContextProvider } from './context/AppContext';

function App() {

	return (
		<BrowserRouter>
			<DommusPromiseLoading/>
			<AppContextProvider>
				<Routes/>
			</AppContextProvider>
		</BrowserRouter>

	);
}

export default App;

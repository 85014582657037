import React, {useEffect, useState} from 'react';
import {Switch, Route} from 'react-router-dom';
import {useHistory} from "react-router-dom";
import Dashboard from './pages/Dashboard/';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Auth from './pages/Auth';
import Base from './base.js';
import guService from "./services/guService";
import PermissaoUsuario from "./pages/Permissao/Usuario";
import PermissaoTipo from "./pages/Permissao/Tipo";
import ModuloLogout from "./pages/Logout/ModuloLogout";
import Validacao from "./pages/Auth/Validacao";
import RecuperarSenha from "./pages/Login/RecuperarSenha";
import AlterarSenha from "./pages/Login/AlterarSenha";
import InvalidarToken from "./pages/Login/InvalidarToken";
import { ConfiguracaoAPI } from './components/ConfiguracaoAPI';
import LoginVendas from './pages/LoginVendas/index.js';


export default function Routes() {
    const token = localStorage.getItem('@DommusGU-token');
    const [tokenValido, setTokenValido] = useState(null);
    const telas = localStorage.getItem('@DommusGU-telas');
    const history = useHistory();
    const idUsuario = localStorage.getItem('@DommusGU-idUsuario');
    const usuariosTelaAPI = localStorage.getItem('@DommusGU-usuarios-tela-api');

    useEffect(() => {
        const path = history.location.pathname;
        if(
          !path.includes('alterar-senha') &&
          !path.includes('invalidar-token') &&
          !path.includes('recuperar-senha') &&
          !path.includes('sair') &&
          !path.includes('login')
        ) {
            if(token){
                guService.validaToken(token).then(res => {
                    if(res) {
                        guService.setLocalState('slugCliente', res.data?.slug_cliente)
                        setTokenValido(res.data.valido);
                        if (!res.data.valido) {
                            guService.logout();
                        }else{
                          try{
                            document.cookie = "gu_access_token=" + token + "; Domain=dommus.com.br; Secure; Path=/;";
                          }catch(error){
                            console.log(error)
                          }
                        }
                    }
                })
            } else {
                history.push('/login');
            }
        }
        if(path == '/login') {
            guService.setLocalState('rotaAnterior', '/login')
        }
    }, [token])

    return (
      <Switch>
          <Route path='/login' exact component={Login}/>
          <Route path='/login/:slugCliente' exact component={LoginVendas}/>
          <Route path='/recuperar-senha' exact component={RecuperarSenha}/>
          <Route path='/alterar-senha/:token' exact component={AlterarSenha}/>
          <Route path='/invalida-token/:token' exact component={InvalidarToken}/>
          <Route path='/sair' exact component={Logout}/>
          <Route path='/auth' exact component={Auth}/>
          <Route path='/modulo/logout' exact component={ModuloLogout}/>
          {tokenValido &&
          <Base history={history} conteudo={
              <div style={{height: "100%"}}>
                  {
                      telas.includes('dashboard') &&
                      <>
                          <Route path='/' exact component={Validacao}/>
                          <Route path='/dashboard' exact component={Dashboard}/>
                      </>
                  }
                  {
                  usuariosTelaAPI.includes(Number(idUsuario)) &&
                      <>
                          <Route path='/configuracao-api' exact component={ConfiguracaoAPI}/>
                      </>
                  }

                  {
                      telas.includes('permissao-tipo') &&
                      <Route path='/permissao-tipo' exact component={PermissaoTipo}/>
                  }
                  {
                      telas.includes('permissao-usuario') &&
                      <Route path='/permissao-usuario' exact component={PermissaoUsuario}/>
                  }
              </div>}>
          </Base>}
      </Switch>
    );
}

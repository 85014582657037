import React, { Component, useEffect, useState } from 'react';
import '../MenuLateral/index.css';
import {
  faStream,
  faPowerOff,
  faChartBar,
  faCog,
  faArrowLeft,
  faTicketAlt,
  faCommentAlt,
  faHeadphonesAlt,
  faEnvelope, faUsers, faUserTag, faTachometerAlt, faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { faWhatsapp, faFacebookSquare, faLinkedin, faChrome } from '@fortawesome/free-brands-svg-icons';
import guService from "../../services/guService";

export default function MenuTopo(props) {
  const [open, setOpen] = useState(props.open);
  const [openConfiguracoes, setOpenConfiguracoes] = useState(props.openConfiguracoes);
  const [openAtendimento, setOpenAtendimento] = useState(props.openAtendimento ?? false);


  // componentDidMount(){
  // 		const novoOpen = localStorage.getItem('dommusMenuMobile')
  // 		this.setState({ open: novoOpen })
  // 		console.log(this.state)
  // }


  function mudarEstado(e) {
    e.preventDefault();
    setOpen(!open);
  }


  function abrirConfiguracoes(e) {
    e.preventDefault();
    setOpenConfiguracoes(!openConfiguracoes);
  }

  function abrirAtendimento(e) {
    e.preventDefault();
    setOpenAtendimento(!openAtendimento);
  }


  function logout(e) {
    e.preventDefault();
    guService.logout();
  }

  const classeAsideSidebar = 'sidebar' + (open ? '' : ' hide');
  const classeSidebarWrapper = 'sidebar-wrapper' + (open ? '' : ' is-closed');
  const classeAtendimento = 'suporte is-menu ' + (openAtendimento ? ' open' : '');
  const classeConfiguracoes = 'suporte is-menu ' + (openConfiguracoes ? ' open' : '');
  const telas = localStorage.getItem('@DommusGU-telas');
  const idUsuario = localStorage.getItem('@DommusGU-idUsuario'); 
  const usuariosTelaAPI = localStorage.getItem('@DommusGU-usuarios-tela-api'); 
  
  return (
    <>
      <aside className={classeAsideSidebar}>
        {!open ?
          <ReactTooltip /> : <></>}
        <div className={classeSidebarWrapper}>
          <div className="sidebar-title">
            <span>Menu</span>
            <a href="#" data-tip="Menu" onClick={mudarEstado}>
              <FontAwesomeIcon icon={faStream} />
            </a>
          </div>
          <div className="siderbar-holder">
            <div className="menu-structure">
              <ul>
                {
                  telas.includes('dashboard') &&
                  <li>
                    <Link to="/dashboard">
                      <FontAwesomeIcon color="#F44336" icon={faTachometerAlt} data-tip="Dashboard" />
                      <p>Dashboard</p>
                    </Link>
                  </li>
                }
                
                {
                  usuariosTelaAPI.includes(Number(idUsuario)) &&
                  <li>
                    <Link to="/configuracao-api">
                      <FontAwesomeIcon 
                      color="#72B7BC"
                      icon={faWrench} 
                      data-tip="Configurações de API" />
                      <p>Configurações de API</p>
                    </Link>
                  </li>
                }

                {
                  guService.getLocalState('mostrarGerenciamentoDeUsuarios') &&
                  telas.includes('permissao-tipo') &&
                  <li>
                    <Link to="/permissao-tipo">
                      <FontAwesomeIcon color="#0a71b2" icon={faUserTag} data-tip="Tipos de Usuários" />
                      <p>Tipos de Usuários</p>
                    </Link>
                  </li>
                }

                {
                  guService.getLocalState('mostrarGerenciamentoDeUsuarios') &&
                  telas.includes('permissao-usuario') &&
                  <li>
                    <Link to="/permissao-usuario">
                      <FontAwesomeIcon color="#2d4e1a" icon={faUsers} data-tip="Usuários" />
                      <p>Usuários</p>
                    </Link>
                  </li>
                }

              </ul>
            </div>
            <div className="info-structure">
              <ul>
                <li onClick={abrirConfiguracoes}>
                  <a href="#">
                    <FontAwesomeIcon icon={faCog} color="#f19d1f" data-tip="Configurações" />
                    <p>Configurações</p>
                  </a>
                </li>

                <li onClick={logout}>
                  <Link to="/sair">
                    <FontAwesomeIcon icon={faPowerOff} color="#f44336" data-tip="Sair" />
                    <p>Sair</p>
                  </Link>
                </li>
              </ul>
              <div className="copy">
                Copyright © 2020 <a href="https://www.linkedin.com/company/dommus-sistemas/">Dommus
                                Sistemas</a>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <div className="menu-sections">
        <section className={classeAtendimento}>
          <header>
            Atendimento ao Usuário
                        <div className="close-pane" onClick={abrirAtendimento}>
              <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
            </div>
          </header>
          <h6>Suporte:</h6>
          <ul className="chat">
            <li>
              <a href="https://g3comunicacao.tomticket.com/?account=1390400P23042018093406"
                target="_blank">
                <FontAwesomeIcon icon={faTicketAlt} color="#ffcd56" />
                <p>Ticket</p>
              </a>
              <span>|</span>
              <a href="https://g3comunicacao.tomticket.com/chat/?id=EP21725&amp;ac=1390400P23042018093406"
                target="_blank">
                <FontAwesomeIcon icon={faCommentAlt} color="#ffcd56" />
                <p>Chat</p>
              </a>
            </li>
          </ul>
          <h6>Telefones:</h6>
          <ul className="telefones">
            <li>
              <a href="tel:+55 31 38899776">
                <FontAwesomeIcon icon={faHeadphonesAlt} color="#673AB7" />
                <p>31 3889 - 9776</p>
              </a>
            </li>
            <li>
              <a href="tel:+55 31 984895755">
                <FontAwesomeIcon icon={faWhatsapp} color="#009688" />
                <p>31 98489 - 5755</p>
              </a>
            </li>
          </ul>
          <h6>Email:</h6>
          <ul className="email">
            <li>
              <a href="mailto:comercial@dommus.com.br">
                <FontAwesomeIcon icon={faEnvelope} color="#E57373" />
                <p>comercial@dommus.com.br</p>
              </a>
            </li>
          </ul>
          <h6>Redes Sociais:</h6>
          <ul className="redes-sociais">
            <li>
              <a href="https://www.facebook.com/dommussistemas/" target="_blank">
                <FontAwesomeIcon icon={faFacebookSquare} color="#3b5998" />
                <p>Facebook</p>
              </a>
              <span>|</span>
              <a href="https://www.linkedin.com/company/dommus-sistemas/" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} color="#3b5998" />
                <p>LinkedIn</p>
              </a>
            </li>
          </ul>
          <h6>Site:</h6>
          <ul className="website">
            <li>
              <a href="https://website.dommus.com.br" target="_blank">
                <FontAwesomeIcon icon={faChrome} color="#f19d1f" />
                <p>Website</p>
              </a>
            </li>
          </ul>
          <h6>V 0.0.1</h6>
        </section>
        <section className={classeConfiguracoes}>
          <header>
            Configurações
                        <div className="close-pane" onClick={abrirConfiguracoes}>
              <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
            </div>
          </header>
          <ul className="chat">
            <li>
              <a href="">
                <p>Perfil do usuário</p>
              </a>
            </li>
          </ul>

        </section>
      </div>
    </>
  )
}


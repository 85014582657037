import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Col} from "react-bootstrap";
import ModuloIcon from "../../components/ModuloIcon";
import guService from "../../services/guService";
import Swal from "sweetalert2";
import {trackPromise} from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function CardModulo({modulo}) {

  const [ativo, setAtivo] = useState(false);
  const id_modulo_crm = 1
  const id_modulo_vendas = 2

  useEffect(() => {
    if(modulo.modulo_usuario && modulo.modulo_usuario.status && modulo.modulo_usuario.cliente_modulo.status) {
      setAtivo(true);
    }
  }, [modulo])

  const handleAcessarModulo = useCallback(modulo => {
    if(!ativo) {
      return;
    }
      trackPromise(
        guService.modulo.acessar(modulo.id).then(res => {
          if(Number(modulo.id) === id_modulo_vendas){
            window.location.href = `${res?.data?.modulo?.uri}:${guService.getLocalState('token')}`
          }else{
            window.location.href = `${res.data.modulo.uri}${res.data.token}:${guService.getLocalState('token')}`
          }
        }).catch(err => {
          Swal.fire({
            titleText: "Erro",
            text: "Houve um problema ao autorizar acesso ao módulo " + err,
            icon: 'error'
          });
          return;
        })
      )
  }, [ativo]);

  return (
    <Col  md={6} lg={4} className="mb-4">      
      <div className="modulo-card">
        <div className="icon-section">
          <ModuloIcon sigla={modulo.sigla} ativo={ativo}/>
        </div>
        <div className="conteudo-section">
          <div className="info-section">
            <span className="title">{modulo?.nome}</span>
            <span className="description">{modulo?.descricao}</span>
          </div>          
          <Button
            disabled={!ativo}
            onClick={() => {handleAcessarModulo(modulo)}}
            className="btn-acessar"
            style={{background: ativo ? "var(--dommus-color)" : 'grey'}}
            >
              <span>Acessar</span>
              <FontAwesomeIcon icon={faArrowRight}/>
          </Button>    
        </div>
      </div>
    </Col>
  )
}

import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMailBulk} from "@fortawesome/free-solid-svg-icons";

import ModalDommus from "../../../../../components/DommusModal";
import {trackPromise} from "react-promise-tracker";
import guService from "../../../../../services/guService";
import {Swal} from 'sweetalert2';

export default function EnviarConvite({usuario}) {

  const [openModal, setOpenModal] = useState(false);
  const [senhaAcesso, setSenhaAcesso] = useState(false);

  const handleEnviarConvite = (e) => {
    e.preventDefault();

    trackPromise(
      guService.usuario.enviarConvite(usuario.id).then(res => {
        setOpenModal(true);
        setSenhaAcesso(res.data.password);
      }).catch(err => {
        Swal.fire({
            titleText: "Erro",
            text: "Houve um problema ao enviar convite " + err,
            icon: 'error'
        });
      })
    )
  };

  return (
    <>


    <button onClick={e => {handleEnviarConvite(e)}} className="btn btn-primary">
      <FontAwesomeIcon icon={faMailBulk} />&nbsp; Enviar Convite
    </button>
      <ModalDommus open={openModal} size={"hg"} close={() => {setOpenModal(false)}} title='Convite de Acesso'>
        <div id='modulos'>
          <div id='main'>
            Convite de acesso enviado para {usuario?.nome} - {usuario?.email}
            <br/>
            Senha de Acesso: {senhaAcesso}
            &nbsp;&nbsp;&nbsp;<small><small> será removido após finalização</small></small>
          </div>
          </div>
      </ModalDommus>
    </>

  )
}

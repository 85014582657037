import api from "./api";

const setLocalState = (name, value) => {
    localStorage.setItem('@DommusGU-'+name, value);
};
const getLocalState = name => {
    return localStorage.getItem('@DommusGU-' + name);
};

const guService = {
    login: async ({email, password}) => {
        try {
            const {data} = await api.post('/login', {usuario:email, password})
            return data;
        } catch (e) {
            return e?.response?.data;
        }

    },

    auth: {
        recuperarSenha: email => {
            return api.post('/login/recuperar-senha', {email})
        },
        alterarSenha: (password, token) => {
            return api.post('/login/alterar-senha', {password, token})
        },
        invalidarToken: (token) => {
            return api.post('/login/invalidar-token', {token})
        },
        alterarSenhaComSenhaAleatoria: token => api.put(`/login/${token}/alterar-senha-aleatoria`, null, { headers: { Authorization: "8%TWS#aGdQUhWHmLh%wwYX&e433UiFko" } })
    },

    validaToken: async token => {
        return api.get(`/valida-token/${token}`);
    },

    logout: () => {
        localStorage.removeItem('@DommusGU-token');
        localStorage.removeItem('@DommusGU-telas');
        localStorage.removeItem('@DommusGU-idUsuario');
        localStorage.removeItem('@DommusGU-tipoUsuario');
        localStorage.removeItem('@DommusGU-nomeUsuario');
        if(window.location.pathname !== '/login') {
            window.location = '/login'}
    },

    setLoginInfo: data => {
        setLocalState('token', data.access_token);
        setLocalState('nomeUsuario', data.nome_usuario);
        setLocalState('idUsuario', data.id_usuario);
        setLocalState('tipoUsuario', data.id_usuario_tipo);
        // setLocalState('idCliente', data.id_cliente);
        setLocalState('nomeCliente', data.nome_cliente);
        setLocalState('modulesCount', data.modules_count);
        setLocalState('clientsCount', data.clients_count);
        setLocalState('moduloRedirecionar', data.modulo_redirecionar);
    },

    getLocalState,

    setLocalState,

    cliente: {
        buscar: id => {
            return api.post('usuario-clientes', {id});
        },
        alterar: id_cliente => {
            return api.post(`/seleciona-cliente`, {
                id_cliente
            })
        },
    },

    perfil: {
        listar: id_modulo => {
            return api.post('/perfil/listar', {id_modulo});
        },
        alterarStatus: data => {
            return api.post(`/perfil/alterar-status`, data)
        }
    },

    modulo: {
        acessar: id_modulo => {
            return api.get(`/cliente-acessar-modulo/${id_modulo}`, { headers: { Authorization: guService.getLocalState('token') } });
        },
        listaCliente: () => {
            return api.get(`/modulo/cliente-modulos`);
        },
    },

    dashboard: {
        todosModulos: () => {
            return api.get('/dashboard/modulos', { headers: { Authorization: guService.getLocalState('token') } });
        }
    },

    usuario: {
        listaCliente: moduloSelecionado => {
            return api.get(`/usuario/cliente-usuarios?modulo=${moduloSelecionado}`);
        },
        listaModulos: () => {
            return api.get('/usuario/modulos');
        },
        gravarUsuario: data => {
            return api.post('/usuario/gravar', data);
        },
        buscaPerfilModulo: data => {
            return api.post('/modulo/buscar-perfil-usuario', data)
        },
        alterarStatus: data => {
            return api.post('/usuario/alterar-status', data)
        },
        enviarConvite: id_usuario => {
            return api.get(`/usuario/enviar-convite/${id_usuario}`);
        },
        buscaDadosModulos: () => {
            return api.get(`/busca-dados-modulo`);
        },
        buscarListaUsuarios: () => {
            return api.get('/usuario/cliente-usuarios');
        }
    },
    permissao: {
        buscaPermissao: data => {
            return api.post('/get-permissao', data);
        },
        salvarPermissao: (data, permissao) => {
            return api.post('/set-permissao', {data, permissao});
        }
    },
    configuracao: {
        buscarPorChave: chave =>  api.get(`/configuracao/chave/${chave}`, { headers: { Authorization: "8%TWS#aGdQUhWHmLh%wwYX&e433UiFko" } })
    }
}

export default guService;

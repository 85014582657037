import React, {useEffect, useState} from 'react';
import guService from "../../services/guService";
import {trackPromise} from "react-promise-tracker";
import Swal from "sweetalert2";
import DommusLoading from "../../components/DommusLoading/DommusLoading";

export default function ModuloLogout({ history }) {

    const [dados, setDados] = useState({});
    const [message, setMessage] = useState('Aguarde...');

    useEffect(() => {
        guService.usuario.buscaDadosModulos().then(res => {
            setDados(res.data);
        }).catch(err => Swal.fire({
              titleText: "Erro",
              text: "Houve um problema ao buscar a  " + err,
              icon: 'error'
          })
        )
    }, [])

    useEffect(() => {
        if(dados.clients_count > 1 || dados.modules_count > 1) {
            return window.location = '/dashboard'
        }
        if(dados.clients_count === 1 && dados.modules_count === 1) {
            return window.location = '/sair';
        }

        if(dados.clients_count === 0) {
            setMessage('você não tem nenhuma incorporadora vinculada, entre em contato com e solicite seu acesso');
        }

        if(dados.clients_count === 1 && dados.modules_count === 0) {
            setMessage('você não tem nenhum módulo vinculado, entre em contato com a incorporadora e solicite seu acesso');
        }


    },[dados])



    return (<>{message}</>);


}

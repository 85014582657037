import React from "react";
import "./index.scss"

export function CardCliente({ cliente, handleChangeCliente }) {
    return (
        <div
            className="card-cliente"
            onClick={() => {
                handleChangeCliente(cliente.id);
            }}
        >
            <div className="cliente-logo">
                {cliente.logo && (
                    <img src={cliente.logo} alt={cliente.nome} />
                )}
            </div>
            <div className="cliente-nome">
                <span>{cliente.nome}</span>
            </div>
        </div>
    );
}

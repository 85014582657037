import React from 'react';
import {Modal} from 'react-bootstrap';
import './index.css';

export default function DommusModal({children, open, close, size, title, content, ...rest}) {

	return (
		<Modal
			show={open}
			onHide={close}
			size={size || "lg"}
			{...rest}
			centered
			style={{
				zIndex: '1040',
				paddingRight: '17px',
				display: 'block'
			}}>
			<Modal.Header closeButton>
				<Modal.Title><h2>{title}</h2></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className={"conteudo"}>
					{content}
					{children}
				</div>
			</Modal.Body>
		</Modal>
	);
}

import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import DesenvolvedoresService from '../../../services/desenvolvedorService';
import DommusToggle from '../../DommusToggle';
import UsuariosDesenvolvedor from '../UsuariosDesenvolvedor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import DommusModal from '../../DommusModal';
import ConfigUsuarioAPI from '../ConfigUsuarioAPI';
import './style.css';
import ReactTooltip from 'react-tooltip';

export default function TabUsuariosClienteDesenvolvedor() {
    const [rows, setRows] = useState([]);
    const idCliente = localStorage.getItem('@DommusGU-idCliente');
    const [tipoModalAcessos, setTipoModalAcessos] = useState('Criar');

    const [modulo, setModulo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    function buscarUsuariosAcesso() {
        trackPromise(DesenvolvedoresService.buscarModulosDesenvolvedor({ id_cliente: idCliente }))
            .then((response) => {
                setRows(response.data)
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
            })
    }

    useEffect(() => {
        buscarUsuariosAcesso()
    }, [])

    const abrirModal = (linha) => {
        setModulo(linha.id)
        setIsModalOpen(true)
    }

    const fecharModal = () => {
        setIsModalOpen(false)
    }
    

    function montaTitulo(linha) {
        return (<>
            <span>
            <Button data-tip={'Cadastrar Usuário e Atribuir Acessos'} onClick={(e) => abrirModal(linha)} className='titulo-botao-criar-modulo'><FontAwesomeIcon icon={faPlus} /></Button>
            {linha.nome} 
            </span>
        </>)
    }

    return (
        <>
            {rows.map(row => {
                return <>
                    <ReactTooltip />
                    <DommusToggle title={montaTitulo(row)} toggle={false} >
                        <UsuariosDesenvolvedor id_cliente={idCliente} id_modulo={row.id} />
                    </DommusToggle>

                    <DommusModal
                        open={isModalOpen}
                        close={(e) => setIsModalOpen(false)}
                        titulo={"Usuário"}
                        size={"lg"}
                        centered
                        content={<ConfigUsuarioAPI  modulo={modulo} tipo={tipoModalAcessos} fecharModal={fecharModal} />}
                    >
                    </DommusModal>
                </>
            }
            )}
        </>
    )
}

import React from 'react';
import './styles.css'

export default function DommusBarraTitulo({children}) {
    return (
        <div className="barra-titulo">
            {children}
        </div>
    );
}

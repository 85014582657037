import React from 'react';
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faBullhorn, faChartLine, faClipboardList, faComments, faEnvelope, faFileAlt, faFileSignature, faGlassCheers, faHeadphones, faPhoneAlt, faShoppingBag, faSms, faUsers, faVideo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

export default function ModuloIcon({sigla, ativo}) {

    const midiaMap = {
        CRM: {
            descricao: 'CRM',
            icone: faUsers,
            cor1: "rgb(10, 113, 178)",
            cor2: "rgba(10, 113, 178, 0.8)",
            cor3: "rgba(10, 113, 178, 0.3)"
        },
        VE: {
            descricao: 'Vendas',
            icone: faShoppingBag,
            cor1: "rgb(241, 157, 31)",
            cor2: "rgba(241, 157, 31, 0.8)",
            cor3: "rgba(241, 157, 31, 0.3)"
        },
        GA: {
            descricao: 'Gestão de Assinaturas',
            icone: faFileSignature,
            cor1: "rgb(2, 108, 124)",
            cor2: "rgba(2, 108, 124, 0.8)",
            cor3: "rgba(2, 108, 124, 0.3)",
        },
        NN: {
            descricao: 'Novos Negócios',
            icone: faGlassCheers,
            cor1: "rgb(76, 175, 80)",
            cor2: "rgba(76, 175, 80, 0.8)",
            cor3: "rgba(76, 175, 80, 0.3)"
        },
        DP: {
            descricao: 'Documentação e Projetos',
            icone: faFileAlt,
            cor1: "rgb(100, 181, 246)",
            cor2: "rgba(100, 181, 246, 0.8)",
            cor3: "rgba(100, 181, 246, 0.3)",
        },
        CA: {
            descricao: 'Central de Atendimento',
            icone: faHeadphones,
            cor1: "rgb(253, 126, 20)",
            cor2: "rgba(253, 126, 20, 0.8)",
            cor3: "rgba(253, 126, 20, 0.3)",
        },
        BI: {
            descricao: 'BI - Business Intelligence',
            icone: faChartLine,
            cor1: "rgb(130, 95, 7)",
            cor2: "rgba(130, 95, 7, 0.8)",
            cor3: "rgba(130, 95, 7, 0.3)"
        },
        PV: {
            descricao: 'Portal de Vendas',
            icone: faBullhorn,
            cor1: "rgb(244, 67, 54)",
            cor2: "rgba(244, 67, 54, 0.8)",
            cor3: "rgba(244, 67, 54, 0.3)"
        },
        DK: {
            descricao: 'Desk',
            icone: faClipboardList,
            cor1: "rgb(53, 50, 82)",
            cor2: "rgba(53, 50, 82, 0.8)",
            cor3: "rgba(53, 50, 82, 0.3)"
        },
    }

    let midiaSelecionada = midiaMap[sigla.toUpperCase()];   

    if(!midiaSelecionada) {
        midiaSelecionada = {
            icone: faBullhorn,
            descricao: 'DEFAULT',
            cor: 'grey',
        };
    }



    const icon = midiaSelecionada.icone;
    const color1 = ativo ? midiaSelecionada.cor1 : 'rgb(180, 180, 180)';
    const color2 = ativo ? midiaSelecionada.cor2 : 'rgba(180, 180, 180, 0.8)';
    const color3 = ativo ? midiaSelecionada.cor3 : 'rgba(180, 180, 180, 0.3)';
    const description = midiaSelecionada.descricao;
    return (
        <div className="icone" 
                style={{
                    backgroundImage: `linear-gradient(150deg, ${color1}, ${color2}, ${color3})`
                }}
            >
            <ReactTooltip/>
            <FontAwesomeIcon
                data-tip={description}
                icon={icon}                
            />
        </div>
    )
}

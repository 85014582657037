import React, { useEffect } from 'react';
import guService from '../../services/guService';
export default function Logout({ history }) {
    useEffect(() => {
        localStorage.clear();
        const slugCliente = guService.getLocalState('slugCliente')
        window.location = slugCliente ? `https://${slugCliente}.dommus2.com.br/2.0/sairGU.php` : '/login';
    }, []);

    return (<></>)
}

import React, {useCallback, useMemo, useState} from 'react';
import DommusCheckboxToggle from "../DommusCheckboxToggle";
import PermissaoArea from "./PermissaoArea";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

export default function PermissaoTela({tela, permiteAlterar, handleChangePermissao}) {

    const handleChangePermissaoTela = useCallback(area => {
        const indexAcao = tela.areas.findIndex(areaTela => areaTela.path === area.path);
        tela.areas[indexAcao] = area;
        handleChangePermissao(tela);
    }, [tela, handleChangePermissao])

    const [status, setStatus] = useState(false);

    const handleChangePermissaoSubAreas = useCallback(() => {
        // setStatus(value => !value)
    }, []);

    const [toggleLista, setToggleLista] = useState(true);
    const handleToggle = useCallback(e => {
        e.preventDefault();
        setToggleLista(value => !value);
    }, [])

    const areasRender = useMemo(() => {

        return tela.areas.map(area => (
            <PermissaoArea
                key={area.path}
                area={area}
                permiteAlterar={permiteAlterar}
                handleChangePermissaoTela={handleChangePermissaoTela}
            />
        ))
    }, [tela, permiteAlterar])

    return (
        <ul className="tela">
            <li>
                <div className="info">
                    <a href="#" onClick={(event) => handleToggle(event)} className="expandir">
                        {toggleLista ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
                    </a>
                    <div className="label">
                        {tela.label}
                    </div>
                    <div className="toggle">
                        {/*<DommusCheckboxToggle
                            value={status}
                            setValue={handleChangePermissaoSubAreas()}
                            tooltip=""
                        />*/}
                    </div>
                </div>
                <ul className="area">
                    {toggleLista && areasRender}
                </ul>
            </li>
        </ul>
    );
}

import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { faDice } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CriarDesenvolvedor({ dev, handleFecharModal, infoDev, setInfoDev, tipo }) {

    const [nomeDesenvolvedor, setNomeDesenvolvedor] = useState(dev.nome_desenvolvedor);
    const [chave, setChave] = useState('');
    const idUsuario = localStorage.getItem('@DommusGU-idUsuario');
    const usuariosConfigAPI = localStorage.getItem('@DommusGU-usuarios-config-api');

    function gerarChave() {
        const listaChar = 'ABCÇDEFGHIJKLMNOPQRSTUVWXYZabcçdefghijklmnopqrstuvwxyz0123456789';
        let palavraAleatoria = '';
        let tamanhoMax = 50;
        for (let i = 0; i < tamanhoMax; i++) {
            const index = Math.floor(Math.random() * listaChar.length);
            palavraAleatoria += listaChar[index];
        }
        setChave(palavraAleatoria)
    }

    useEffect(() => {
        setInfoDev({ chave: chave, nome_desenvolvedor: nomeDesenvolvedor, id: dev.id })
    }, [nomeDesenvolvedor, chave])


    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col xs={4}>
                            <Form.Group>
                                <Form.Label>Nome do Desenvolvedor:</Form.Label>
                                <Form.Control
                                    defaultValue={nomeDesenvolvedor}
                                    onChange={(e) => { setNomeDesenvolvedor(e.target.value) }}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Frase de Criação:</Form.Label>
                                <Form.Control
                                    defaultValue={chave}
                                    value={chave}
                                    onChange={(e) => { setChave(e.target.value) }}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <label>Gerar Chave:</label><br></br>
                            <Button
                                data-tip="Gerar Frase Aleatória"
                                variant='primary'
                                id="campo-gerar-chave"
                                onClick={(e) => gerarChave()}
                            ><FontAwesomeIcon icon={faDice} />
                            </Button>
                        </Col>
                    </Row>
                    {usuariosConfigAPI.includes(idUsuario) && tipo == "Editar" &&
                        <Row>
                            <Col>
                                <label>Identificador:</label><br></br>
                                <Alert variant='success'>{dev.identificador}</Alert>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </>
    )
}

import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {trackPromise} from "react-promise-tracker";
import guService from "../../../services/guService";
import Swal from "sweetalert2";
import {Alert, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import './style.scss'
import DommusLoading from "../../DommusLoading/DommusLoading";
import { ClienteContext } from '../../../context/ClienteContext';
import DommusModalSemHeader from '../../DommusModalSemHeader';
import { CardCliente } from './CardCliente';

export default function SelecionaCliente ({showModal}) {

    const [isLoading, setIsLoading] = useState(false);
    const [mostraModalSelecionarCliente, setMostraModalSelecionarCliente] = useState(false);
    const [openBarraPesquisa, setOpenBarraPesquisa] = useState(false);
    const [termoBuscaCliente, setTermoBuscaCliente] = useState("");
    const inputPesquisaRef = useRef(null);
    const { clientesLista, clienteSelecionado } = useContext(ClienteContext);

    const handleFecharModalCliente = useCallback(() => {
        const idCliente = guService.getLocalState('idCliente')
        if(!idCliente || (idCliente === 'null')) {
            Swal.fire({
                titleText: "Atenção",
                text: "Selecione uma Empresa para continuar",
                icon: 'warning'
            });
            return;
        }

        setMostraModalSelecionarCliente(false);
    }, []);

    useEffect(() => {setMostraModalSelecionarCliente(showModal)}, [showModal])

    const handleChangeCliente = useCallback(id => {
        guService.setLocalState('idCliente', id);

        trackPromise(
            guService.cliente.alterar(id).then(res => {
                const loginData = res.data;
                if(loginData.valido) {
                    if(loginData.bloquear_sistema){
                    Swal.fire({
                        titleText: "Atenção",
                        text: "Conta Bloqueada. Entre em contato com a nossa equipe,através dos nossos canais de atendimento, para verificar o motivo do bloqueio. Obrigado.",
                        icon: 'warning'
                    });
                    return;
                    }
                    // setNomeCliente(loginData.nome_cliente)
                    setIsLoading(true);
                    guService.setLoginInfo(loginData);
                    window.location.reload();
                }

            }).catch(err => {
                setIsLoading(false);
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao buscar selecionar o cliente " + err,
                    icon: 'error'
                });
                return;
            })
        )

        handleFecharModalCliente();
    }, [handleFecharModalCliente]);

    const verificaClientes = useCallback(() => {
        let idClienteSelecionado = guService.getLocalState('idCliente');        
        if(clientesLista.length){            
            if(!clientesLista.length || (clientesLista.length > 1 && (!idClienteSelecionado || idClienteSelecionado === 'null'))) {
                setMostraModalSelecionarCliente(true)
            }
        }      
    }, [clientesLista, clienteSelecionado]);

    useEffect(()=>{
        if(inputPesquisaRef.current){
            inputPesquisaRef.current.focus();
        }
    },[openBarraPesquisa])

    const handleBarraPesquisa = ()=>{
        let temp = !openBarraPesquisa;
        setOpenBarraPesquisa(temp);
        setTermoBuscaCliente("");
    }

    const barraPesquisa = ()=>{
        return(
            <div className='pesquisa-cliente-modal-section'>
                <button className='btn-pesquisa-cliente' onClick={()=>{handleBarraPesquisa()}}>
                    {openBarraPesquisa ? 
                    <FontAwesomeIcon icon={faTimes} style={{margin: "0px 2px"}}/>
                    :<FontAwesomeIcon icon={faSearch}/>
                    }
                </button>
                <div className={`input-pesquisa-cliente-section ${openBarraPesquisa ? 'show' : ''}`}>
                    <input 
                        ref={inputPesquisaRef} 
                        className="input-pesquisa-cliente" 
                        value={termoBuscaCliente}
                        onChange={(e)=>{
                            setTermoBuscaCliente(e.target.value);
                        }}
                    />
                    <FontAwesomeIcon 
                        className="btn-limpar-busca"icon={faTimes} 
                        onClick={()=>{
                                setTermoBuscaCliente("")
                                if(inputPesquisaRef.current){
                                    inputPesquisaRef.current.focus();
                                    inputPesquisaRef.current.value = ""
                                }
                            }
                        }
                    />
                </div>
            </div>
        )
    }

    const filtrarClientes = ()=>{
        let clientesFiltrados = clientesLista.filter((cliente)=>{
            return cliente.nome.toLowerCase().includes(termoBuscaCliente.toLowerCase());
        })

        if(clientesFiltrados.length){
            return clientesFiltrados.map((cliente, index) => (
                        <Col key={index} md={4} className='mb-4'>
                            <CardCliente cliente={cliente} handleChangeCliente={handleChangeCliente}/>
                        </Col>
                    ));
        }else{
            return <Alert style={{width: "100%", textAlign: "center", margin: "20px"}} variant='warning'>Nenhum cliente encontrado</Alert>
        }
    }

    useEffect(() => {
        verificaClientes()
    }, [clientesLista, clienteSelecionado]);

    return (
        <>
            {isLoading && <DommusLoading/>}
            <DommusModalSemHeader 
                open={mostraModalSelecionarCliente} 
                className="clientes-modal" close={handleFecharModalCliente}
                title="Selecione uma Empresa:"
                itensHeader={barraPesquisa()}
            >
                <Row className="justify-content-md-center m-2">
                    {
                        termoBuscaCliente ? 
                            filtrarClientes()
                        :
                        clientesLista.map((cliente, index) => (
                            <Col key={index} md={4} className='mb-4'>
                                <CardCliente cliente={cliente} handleChangeCliente={handleChangeCliente}/>
                            </Col>
                        ))
                    }
                    {
                        !clientesLista.length && <h4>Você não está cadastrado a nenhum cliente</h4>
                    }
                </Row>
            </DommusModalSemHeader>
            <button className='selecao-cliente' onClick={()=>{setMostraModalSelecionarCliente(true)}}>
                {clienteSelecionado?.logo && <img src={clienteSelecionado?.logo} alt={clienteSelecionado?.cliente}/>}
            </button>
        </>
    )
}

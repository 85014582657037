import React, {useEffect, useState} from "react";
import DommusCheckboxToggle from "../../../../../components/DommusCheckboxToggle";
import {trackPromise} from "react-promise-tracker";
import guService from "../../../../../services/guService";
import {Swal} from 'sweetalert2';

export default function StatusUsuarioModulo({usuario, modulo, ativo, habilitado, handleChangeAtivo, moduloSelecionado}){

  const [status, setStatus] = useState(!!ativo)
  const handleChangePermissaoAcao = value => {


    const data = {
      id_usuario: usuario.id,
      status: value,
      id_modulo: moduloSelecionado
    }
    trackPromise(
      guService.usuario.alterarStatus(data).then(res => {
        handleChangeAtivo(res.data.status)
        setStatus(res.data.status)
      }).catch(err =>{
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao alterar o status " + err.response.data,
          icon: 'error'
        });
      })
    )
  }

  useEffect(() => {
    setStatus(!!ativo)
  }, [status, ativo])


  return (
    <div className="altera-status">
      <div className="label">Status</div>
      <DommusCheckboxToggle
        label=""
        value={status}
        setValue={handleChangePermissaoAcao}
        tooltip={status ? 'Ativar' : 'Desativar'}
        habilitado={habilitado}
      />
    </div>
  )
}

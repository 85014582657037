import React, {useCallback, useEffect, useState} from 'react';
import DommusCheckboxToggle from "../DommusCheckboxToggle";

export default function PermissaoAcao({acao,permiteAlterar,handleChangePermissaoArea}) {

    const [status, setStatus] = useState(acao.status);

    const handleChangePermissaoAcao = useCallback(newStatus => {
        setStatus(newStatus);
        acao.status = newStatus;
        handleChangePermissaoArea(acao);
    }, [acao, handleChangePermissaoArea]);

    return (
        <li>
            <div className="info">
            <div className="label">
                {acao.label}
            </div>
            <div className="toggle" style={{marginLeft: 16}}>
                <DommusCheckboxToggle
                    label=""
                    value={status}
                    setValue={handleChangePermissaoAcao}
                    tooltip={acao.label}
                    habilitado={permiteAlterar}
                />
            </div>
            </div>
        </li>
    );
}

import './index.css';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";

function DommusToggle(props) {
	const [toggle, setToggle] = useState(props.toggle || false);

    useEffect(() => {
        setToggle(props.toggle);
    }, [props.toggle])

	const headerClass = toggle ? 'dommus-toggle' : 'dommus-toggle arrow';
	return (
		<>
			<header className={headerClass}>{props.title}<FontAwesomeIcon icon={faAngleDown} onClick={() => {
				setToggle(!toggle)
			}}/></header>
			<div style={{'display': (toggle) ? 'block' : 'none'}} className={props.bodyClass ?? ''}>
				{toggle}
				{props.children}
			</div>
		</>
	)
}

export default DommusToggle;

import React from 'react';
import MenuTopo from './components/MenuTopo';
import MenuLateral from './components/MenuLateral';
import { isMobile } from 'react-device-detect';

export default function Base({conteudo, history}) {
	function verificarOutrasAbas(event) {
	    if (event.data && event.data.type === 'login' && event.data.userLoggedIn) {
	        // Redireciona para a GU
	        window.location.href = 'https://painel.dommus.com.br/dashboard';
	    }
	}
	const broadcastChannel = new BroadcastChannel('dommus_login_channel');
	broadcastChannel.addEventListener('message', verificarOutrasAbas);
	return (
		<div>
			<MenuTopo history={history}/>
			<div className="wrapper">
				{!isMobile &&
					<div className="sidebar">
						<MenuLateral history={history}/>
					</div>
				}
				<div className="content" style={{width: isMobile ? "100%" : "calc(100% - 50px)"}}>
					{conteudo}
				</div>
			</div>
		</div>

	);
}

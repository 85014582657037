import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './coluna.css';
import {trackPromise} from "react-promise-tracker";
import guService from "../../../services/guService";
import Swal from "sweetalert2";

export default function SeletorModulo({isOpen, handleChangeModulo, moduloSelecionado}) {

  const [modulosLista, setModulosLista] = useState([]);

  useEffect(() => {
    trackPromise(
      guService.modulo.listaCliente().then(res => {
        setModulosLista(res.data);
      }).catch(e => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar lista de módulos " + e.response.data,
          icon: 'error'
        });
      })
    )
  }, [])

  const currentClass = useMemo(() => {
    return isOpen ? 'open':'closed';
  }, [isOpen]);



  return (
    <div id='right' className={currentClass} >
      <div className={`sidebar ${currentClass}`} >
        <div className='container-modal-lateral'>
          <div className='interacao-modal-lateral'>
            {modulosLista.map(modulo =>
              <button
                className='botoes-modal-lateral'
                onClick={e => {e.preventDefault(); handleChangeModulo(modulo.id)}}
              >
                {modulo.nome}
              </button>
            )}
          </div>
          <div className='footer-modal-lateral'>

          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect, useCallback } from 'react';
import guService from '../../services/guService';
import '../Login/index.css';
import logo from '../../assets/logo.svg';
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {Link, Redirect, useHistory, useParams} from "react-router-dom";
import {successToast} from "../../components/DommusToast";



export default function InvalidarToken() {
  const {token} = useParams();
  const history = useHistory();

  useEffect(() => {
    guService.auth.invalidarToken(token).then(res => {
      successToast.fire(res.data.message);
      history.push('/login');
    }).catch(err => {
      Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao tentar invalidar o token.",
          icon: 'error'
      });
      history.push('/');
    })
  }, [history, token]);

  return (<></>)
}

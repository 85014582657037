import React, { useEffect, useState } from 'react'
import DesenvolvedoresService from '../../../../services/desenvolvedorService';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { Col, Container, Form, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import guService from '../../../../services/guService';
import './index.css';

export default function AtribuirDesenvolvedor({ setValorAtribuicao }) {

  const [optionsDesenvolvedor, setOptionsDesenvolvedor] = useState([]);
  const [optionsModulos, setOptionsModulos] = useState([]);
  const [atribuicao, setAtribuicao] = useState({
    modulos: null,
    desenvolvedor: null
  });
  const idCliente = localStorage.getItem('@DommusGU-idCliente');


  function buscarTodosDesenvolvedores() {
    trackPromise(DesenvolvedoresService.index())
      .then((response) => {
        let options = response.data.map(desenvolvedor => {
          return { label: desenvolvedor.nome_desenvolvedor, value: desenvolvedor.id }
        })
        setOptionsDesenvolvedor(options)
      }).catch(error => {
        Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
      })
  }

  function buscarModulos() {
    trackPromise(guService.usuario.listaModulos())
      .then((response) => {
        let options = response.data.map(modulo => {
          return { label: modulo?.cliente_modulo?.modulo?.nome, value: modulo?.cliente_modulo?.modulo?.id }
        })
        setOptionsModulos(options)
      }).catch(error => {
        Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
      })
  }

  function handleAtribuicao(campo, valor) {

    setAtribuicao(prev => ({
      ...prev, [campo]: valor
    }))

    if (campo == 'desenvolvedor') {
      trackPromise(DesenvolvedoresService.buscarModulosDesenvolvedor({id_cliente: idCliente , desenvolvedor: valor.value}))
        .then((response) => {
          let modulosValues = response.data?.map(modulo => {
            return { label: modulo.nome, value: modulo.id }
          })
          setAtribuicao(prev => ({
            ...prev, modulos: modulosValues
          }))
        }).catch(error => {
          Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
        })
    }

  }

  useEffect(() => {
    buscarTodosDesenvolvedores()
    buscarModulos()
  }, [])


  useEffect(() => {
    setValorAtribuicao(atribuicao)
  }, [atribuicao])



  return (
    <Container fluid className='container-desenvolvedores'>
      <Row>
        <Col>
          <Form.Label>Desenvolvedor</Form.Label>
          <ReactSelect
            className='select-desenvolvedor-atribuir'
            value={atribuicao.desenvolvedor}
            options={optionsDesenvolvedor}
            onChange={(e) => { handleAtribuicao("desenvolvedor", e) }}
          >
          </ReactSelect>
        </Col>


        <Col>
          <Form.Label>Modulos</Form.Label>
          <ReactSelect
            options={optionsModulos}
            value={atribuicao.modulos}
            onChange={(e) => { handleAtribuicao("modulos", e) }}
            isMulti
          >
          </ReactSelect>
        </Col>
      </Row>
    </Container>
  )
}

import React, { useContext } from "react";
import "./index.scss"
import { ClienteContext } from "../../../context/ClienteContext";
import guService from "../../../services/guService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faCog, faPowerOff } from "@fortawesome/free-solid-svg-icons";

export function MenuMobile(){
    const {clienteSelecionado} = useContext(ClienteContext);


    function logout(e) {
        e.preventDefault();
        guService.logout();
    }

    return(
        <div className="menu-mobile">
            <div className="header">
                <span className="user-name-mobile">Olá, {guService.getLocalState('nomeUsuario')}</span>
                <span className="client-name-mobile">{clienteSelecionado?.nome ?? "Cliente não selecionado"}</span>
            </div>
            <div className="itens-menu">
                <div className="itens-principais">
                    <div className="item-menu">
                        <FontAwesomeIcon icon={faChartBar} style={{color:"#F44335"}}/>
                        <span>Dashboard</span>
                    </div>
                </div>
                <div className="itens-footer">
                    <div className="item-menu">
                        <FontAwesomeIcon icon={faCog} style={{color:"#F19D1E"}}/>
                        <span>Configurações</span>
                    </div>
                    <div className="item-menu" onClick={(e)=>{logout(e)}}>
                        <FontAwesomeIcon icon={faPowerOff} style={{color:"#F44335"}}/>
                        <span>Sair</span>
                    </div>
                    <div className="copy">
                        <span>Copyright © 2020</span> 
                        <a href="https://www.linkedin.com/company/dommus-sistemas/">
                            DommusSistemas
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_GU_API,
    headers: {
        'Authorization': localStorage.getItem('@DommusGU-token')
    }
});

export default api;

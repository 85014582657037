import React, {useContext, useState} from 'react';
import '../MenuTopo/index.css';
import logomarcatopo from '../../assets/logomarca-top-w.26ee63ff.svg';
import logoDommus from '../../assets/logo_domus.png';
import Agenda from "./Agenda";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { faArrowAltCircleRight, faBell, faCalendarAlt, faPowerOff, faStream, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../MenuLateral/index';
import guService from "../../services/guService";
import SelecionaCliente from "./SelecionaCliente";
import { isMobile } from 'react-device-detect';
import { ClienteContext } from '../../context/ClienteContext';
import { MenuMobile } from './MenuMobile';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export default function MenuTopo({history}) {

    const [openAgenda, setOpenAgenda] = useState(false);
    const [openMenuMobile, setOpenMenuMobile] = useState(false);
    const {clienteSelecionado} = useContext(ClienteContext);

    function logout(e) {
        e.preventDefault();
        guService.logout();        
    }

    return (
        <>
            <nav className="dommus-menu">
                <div className="logo">
                    <picture>
                        {isMobile ?
                            <img src={logoDommus} alt="Dommus Sistemas"/>
                        :
                            <>
                                <img src={logomarcatopo} className="logo-padrao" alt="Dommus Sistemas"/>
                                <img src={logoDommus} className="logo-alternativa" alt="Dommus Sistemas"/>
                            </>
                        }
                    </picture>
                </div>
                <div className="right-nav">
                    <div className="info-nav">
                        <div className="online">
                            <span className="span-seleciona-empresa">Trocar de Empresa <FontAwesomeIcon icon={faArrowAltCircleRight}/></span>
                            <SelecionaCliente showModal={false}/>
                            {!isMobile && 
                                <Dropdown>
                                    <Dropdown.Toggle className="perfil-dropdown">
                                            <span className="user-name">Olá, {guService.getLocalState('nomeUsuario')}</span>
                                            <span className='client-name'>{clienteSelecionado?.nome ?? "Cliente não selecionado"}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="4" onClick={(e) => logout(e)} className='item-menu-usuario'>
                                            <Link to="/sair">
                                                <FontAwesomeIcon icon={faPowerOff} style={{color: "#F44335"}}/>
                                                <span> Sair </span>
                                            </Link>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                        <picture className={"picture-topo"} onClick={() => {
                            setOpenAgenda(statusAtual => {
                                return !statusAtual
                            })
                        }}>
                            <FontAwesomeIcon icon={faCalendarAlt} size='xs'/>
                        </picture>
                        <picture className={"picture-topo"}>
                            <FontAwesomeIcon icon={faBell} size='xs'/>
                        </picture>
                        {isMobile &&
                                <button className='btn-menu-mobile' onClick={()=>{
                                    setOpenMenuMobile((current)=>!current);
                                }}>
                                    {openMenuMobile ?
                                        <FontAwesomeIcon icon={faTimes} />
                                        :
                                        <FontAwesomeIcon icon={faStream} className="barsMenuMobile"/>
                                    }
                                </button>
                        }
                        {isMobile && openMenuMobile && 
                            <MenuMobile/> 
                        }
                    </div>
                </div>
            </nav>
            <Agenda openAgenda={openAgenda} closeAgenda={() => {
                setOpenAgenda(false)
            }}/>
        </>

    )

}

import React, { useState, useCallback } from 'react';
import './style.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

import FiltroUsuarios from './FiltroTipo';
import ListaUsuarios from './ListaPerfis';

import ModalDommus from "../../../components/DommusModal";
import ModalPerfil from "./ListaPerfis/ModalPerfil";


export default function PermissaoUsuario() {
  const [openModal, setOpenModal] = useState(false);
  const [moduloSelecionado, setModuloSelecionado] = useState(null);
  const [perfilAtual, setPerfilAtual] = useState({});

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleAbrirModal = () => {
    setOpenModal(true);
    setPerfilAtual({});
  }

  const handleModuloSelecionado = id_modulo => {
    setModuloSelecionado(id_modulo)
  }

  const handleEditarPerfil = perfil => {
    setPerfilAtual(perfil);
    setOpenModal(true);
  }

  return (
    <>
      <div className='cadastro-usuario'>
        Cadastro de Perfil
        <button className='btn-novo' onClick={handleAbrirModal}>
          <FontAwesomeIcon icon={faUserPlus} />
          NOVO
        </button>
      </div>
      <FiltroUsuarios
        handleModuloSelecionado={handleModuloSelecionado}
        moduloSelecionadoComponente={moduloSelecionado}
      />
      <ListaUsuarios moduloSelecionadoComponente={moduloSelecionado}
                     handleEditarPerfil={handleEditarPerfil}
      />
      <ModalDommus className="modal-usuarios" size={"hg"} open={openModal} close={handleCloseModal} title='Cadastrar Perfil' >
        <ModalPerfil
          closeModal={handleCloseModal}
          moduloSelecionadoComponente={moduloSelecionado}
          handleModuloSelecionado={handleModuloSelecionado}
          perfilAtual={perfilAtual}
        />
      </ModalDommus>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import ReactSelect from 'react-select';
import DesenvolvedoresService from '../../../services/desenvolvedorService';
import guService from '../../../services/guService';
import './index.css';

export default function ConfigUsuarioAPI({ usuarioAcesso = null, modulo, tipo, fecharModal }) {
    const [optionsMetodos, setOptionsMetodos] = useState([]);
    const [optionsRecursos, setOptionsRecurso] = useState([]);
    const [optionsUsuarios, setOptionUsuarios] = useState([]);
    const [acessos, setAcessos] = useState(usuarioAcesso ? usuarioAcesso?.acessos : {});
    const [usuario, setUsuario] = useState(usuarioAcesso ?? {});
    const [acessoHtml, setAcessoHtmls] = useState([]);

    const [formAdicionar, setFormAdicionar] = useState({
        recurso: '',
        usuario: usuarioAcesso,
    });

    const handleChangeSelectAdicionar = (campo, event) => {
        setFormAdicionar({
            ...formAdicionar,
            [campo]: event,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setAcessos(prevAcessos => ({
            ...prevAcessos,
            [formAdicionar?.recurso?.value]: []
        }));
        setUsuario(formAdicionar?.usuario)
        setFormAdicionar({
            ...formAdicionar,
            recurso: null,
        });
    };

    function buscarMetodos() {
        trackPromise(DesenvolvedoresService.buscarMetodos({ id_modulo: modulo }))
            .then((response) => {
                let optionsMetodos = response.data?.map(metodo => {
                    return { label: metodo.metodo.toUpperCase(), value: metodo.id }
                })
                setOptionsMetodos(optionsMetodos)
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
            })
    }

    function buscarUsuarios() {
        trackPromise(guService.usuario.buscarListaUsuarios())
            .then((response) => {
                let optionsUsuario = response.data.map(usuario => {
                    return { value: usuario?.id, label: usuario?.usuario?.nome }
                })
                setOptionUsuarios(optionsUsuario)
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
            })
    }

    function buscarRecursos() {
        trackPromise(DesenvolvedoresService.buscarRecursos({ id_modulo: modulo }))
            .then((response) => {
                let optionsRecurso = response.data?.map(recurso => {
                    return { label: recurso.recurso.toUpperCase(), value: recurso.id }
                })
                setOptionsRecurso(optionsRecurso)
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
            })
    }

    function montaAcessos(acessos) {
        let retorno = Object.entries(acessos).map(([chave, acesso]) => {

            let valueRecurso = null;
            optionsRecursos.forEach(option => {
                if (chave.includes(option.value)) {
                    valueRecurso = option;
                }
            })

            let valueMetodo = optionsMetodos.filter(option => {
                return acesso.includes(String(option.value));
            })
            return { recurso: valueRecurso, metodos: valueMetodo }

        })
        setAcessoHtmls(retorno)
    }

    function removerRecurso(recurso) {
        const novoAcessos = { ...acessos };
        delete novoAcessos[recurso.value];
        setAcessos(novoAcessos);
    }

    function removeOptionUsadas(option) {
        let optionsValidas = option.filter(option => {
            return !Object.keys(acessos)?.includes(String(option.value));
        })
        return optionsValidas ?? [];
    }

    function handleChangeMetodos(metodosSelect, recurso) {
        let metodos = metodosSelect.map(metodo => {
            return String(metodo.value);
        })
        setAcessos(prevAcessos => ({
            ...prevAcessos,
            [recurso?.value]: metodos
        }));

    }

    function atualizarAcessos(acessos) {
        let objetoAcesso = {
            usuario: usuarioAcesso.id,
            modulo: modulo,
            acessos: acessos
        }

        trackPromise(DesenvolvedoresService.salvarAcessos(objetoAcesso))
            .then((response) => {
                fecharModal()
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um erro ao atualizar os acessos..', 'error')
            })
    }

    function salvarAcessos(acessos) {
        let objetoAcesso = {
            usuario: formAdicionar?.usuario?.value,
            modulo: modulo,
            acessos: acessos
        }
        trackPromise(DesenvolvedoresService.salvarAcessos(objetoAcesso))
            .then((response) => {
                fecharModal()
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um erro ao salvar os acessos.', 'error')
            })
    }

    function handleFecharModal() {
        fecharModal()
    }

    useEffect(() => {
        buscarRecursos()
        buscarMetodos()
    }, [])

    useEffect(() => {
        montaAcessos(acessos)
    }, [acessos, optionsMetodos, optionsRecursos])

    useEffect(() => {
        buscarUsuarios()
    }, [tipo == 'Adicionar'])

    return (
        <>
            <br />
            <Container>
                <Form.Group>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            {tipo == 'Criar' && usuarioAcesso == null &&
                                <Col>
                                    <Form.Label>Usuário</Form.Label>
                                    <ReactSelect
                                        isSearchable={true}
                                        options={optionsUsuarios}
                                        name="usuario"
                                        value={formAdicionar.usuario}
                                        onChange={(e) => handleChangeSelectAdicionar('usuario', e)}
                                    ></ReactSelect>
                                </Col>}
                        </Row>
                        <Row>
                            <Col xs={10}>
                                <Form.Label>Recurso</Form.Label>
                                <ReactSelect
                                    options={removeOptionUsadas(optionsRecursos)}
                                    name="recurso"
                                    value={formAdicionar.recurso}
                                    onChange={(e) => handleChangeSelectAdicionar('recurso', e)}
                                ></ReactSelect>
                            </Col>
                            <Col xs={1}>
                                <Form.Label>Ação</Form.Label>
                                <Button variant='primary' type='submit'>Adicionar</Button>
                            </Col>
                        </Row><br />
                    </Form>
                    <Row>
                        {acessoHtml && acessoHtml.map(acesso => {
                            return (<>
                                <Col xs={4}>
                                    <Form.Label> {acesso?.recurso?.label}</Form.Label>
                                    <ReactSelect
                                        options={optionsMetodos}
                                        value={acesso.metodos}
                                        onChange={(e) => handleChangeMetodos(e, acesso?.recurso)}
                                        isMulti
                                    ></ReactSelect>
                                </Col>
                                <Col xs={2}>
                                    <Form.Label>Ação</Form.Label><br />
                                    <Button variant='danger' onClick={(e) => removerRecurso(acesso.recurso)}>Remover</Button>
                                </Col>
                            </>)
                        })}
                    </Row>
                </Form.Group>
            </Container>
            <Container className='container-botao-modal-config'>
                <Row>
                    <Col>
                        {tipo == "Editar" ?
                            <Button variant="success" className="mt-3 float-right botao-modal-config" onClick={(e) => atualizarAcessos(acessos)}>
                                Atualizar
                            </Button> :
                            <Button variant="success" className="mt-3 float-right botao-modal-config" onClick={(e) => salvarAcessos(acessos)}>
                                Salvar
                            </Button>}
                        <Button variant="danger" className="mt-3 float-right botao-modal-config" onClick={(e) => handleFecharModal()}>
                            Fechar
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

import React, {ReactNode, useEffect, useState} from 'react';
import './index.css';

export function DommusTabs({tabs, getActiveTabIndex, goToTab, tabColor, tabActiveColor}) {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setActiveTab(goToTab);
    }, [goToTab]);

    return <>
        <div className="dommus-tab">
            <div className="dommus-tab-titles">
                {tabs.map((tab, index) => {
                    return <div key={index} className={`dommus-tab-title ${activeTab === index ? 'active' : ''}`}
                        onClick={() => {
                            setActiveTab(index);
                            getActiveTabIndex(index)
                        }}
                    style={{backgroundColor: activeTab === index ? tabActiveColor : tabColor}}>{tab.title}</div>
                })}
            </div>
            {tabs.map((tab, index) => {
                return activeTab === index && <div key={index} className="dommus-tab-content">{tab.content}</div>
            })}
        </div>
    </>;
}

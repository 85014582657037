import React, { createContext, useState, useEffect, useCallback } from "react";
import { trackPromise } from "react-promise-tracker";
import guService from "../services/guService";
import Swal from 'sweetalert2'

export const ClienteContext = createContext({});

export function ClienteProvider({ children }) {
    const [clientesLista, setClientesLista] = useState([]);
    const [clienteSelecionado, setClienteSelecionado] = useState();
    const [atualizarModulos, setAtualizarModulos] = useState(false)

    useEffect(() => {
        let usuarioLogado = guService.getLocalState("idUsuario");
        if (usuarioLogado && usuarioLogado !== "null" && usuarioLogado !== "") {
            recarregarClientes();
        }
    }, []);

    useEffect(() => {
        resolverClienteSelecionado();
    }, [clientesLista]);

    const recarregarClientes = () => {
        const tokenUsuario = localStorage.getItem('@DommusGU-token')
        if(!tokenUsuario) {
            return
        }
        trackPromise(
            guService.cliente
                .buscar(guService.getLocalState("idUsuario"))
                .then((res) => {
                    const clientePadrao = guService.getLocalState('clientePadrao')
                    let clientePadraoModuloVendas = null
                    setClientesLista(res.data);
                    if(clientePadrao && Array.isArray(res.data) && res.data?.length) {
                        clientePadraoModuloVendas = res.data.find(modulo => modulo.slug == clientePadrao)
                    }
                    if(!clientePadraoModuloVendas && res?.data?.length == 1) {
                        clientePadraoModuloVendas = res.data[0]
                    }
                    if(clientePadraoModuloVendas) {
                        setClienteSelecionado(clientePadraoModuloVendas)
                        guService.setLocalState('idCliente', clientePadraoModuloVendas.id);
                        trackPromise(
                            guService.cliente.alterar(clientePadraoModuloVendas.id).then(res => {
                                const loginData = res.data;
                                if(loginData.valido) {
                                    if(loginData.bloquear_sistema){
                                        Swal.fire({
                                            titleText: "Atenção",
                                            text: "Conta Bloqueada. Entre em contato com a nossa equipe,através dos nossos canais de atendimento, para verificar o motivo do bloqueio. Obrigado.",
                                            icon: 'warning'
                                        });
                                        return;
                                    }
                                    guService.setLoginInfo(loginData);
                                    setAtualizarModulos(atual => !atual)
                                }
                            }).catch(error => {
                                console.log(error)
                                console.log(error?.response)
                            })
                        )
                    }
                })
                .catch((err) => {
                    console.log(err)
                    console.log(err?.response)
                    return;
                })
        );
    };

    const resolverClienteSelecionado = useCallback(() => {
        let clienteStorage = guService.getLocalState("idCliente");
        if (clienteStorage) {
            let temp = clientesLista.find((cliente) => {
                return cliente.id === parseInt(clienteStorage);
            });
            if (temp) {
                setClienteSelecionado(temp);
            }
        }
    }, [clientesLista]);

    return (
        <ClienteContext.Provider
            value={{
                clientesLista,
                clienteSelecionado,
                setClienteSelecionado,
                recarregarClientes,
                atualizarModulos
            }}
        >
            {children}
        </ClienteContext.Provider>
    );
}

import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Container, Dropdown } from 'react-bootstrap';
import DommusBarraTitulo from '../DommusBarraTitulo';
import ItemBarraTitulo from '../DommusBarraTitulo/ItemBarraTitulo';
import { faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css';
import ReactTooltip from 'react-tooltip';
import { DommusTabs } from '../DommusTabs';
import DommusModal from '../DommusModal';
import ConfigUsuarioAPI from './ConfigUsuarioAPI';
import TabDesenvolvedor from './TabDesenvolvedor';
import CadastroDesenvolvedorAPI from './CadastroDesenvolvedorAPI';
import TabUsuariosClienteDesenvolvedor from './TabUsuariosClienteDesenvolvedor';


export function ConfiguracaoAPI(props) {

  const [isDesenvolvedorModalOpen, setIsDesenvolvedorModalOpen] = useState(false);
  const [isOpenModalAcessos, setIsOpenModalAcessos] = useState(false);
  const [tituloModalDesenvolvedor, setTituloModalDesenvolvedor] = useState('');
  const [tituloModalAcessos, setTituloModalAcessos] = useState('');
  const [tipoModal, setTipoModal] = useState('Criar');
  const [desenvolvedor, setDesenvolvedor] = useState([]);
  const [selectedKey, setSelectedKey] = useState(0);

  function abrirModalDesenvolvedor(titulo, desenvolvedor = []) {
    setTituloModalDesenvolvedor(titulo + ' Desenvolvedor')
    setTipoModal(titulo)
    setDesenvolvedor(desenvolvedor)
    setIsDesenvolvedorModalOpen(true)
  }

  function abrirModalAcessos(titulo) {
    setTituloModalAcessos(titulo + ' Acesso')
    setTipoModal(titulo)
    setIsOpenModalAcessos(true)
  }

  const handleFecharModal = () => {
    setIsDesenvolvedorModalOpen(false)
    setIsOpenModalAcessos(false)
  }

  const tabsSections = useMemo(() => {
    return (
      [{
        id: 1,
        title: "Desenvolvedores",
        content: <TabDesenvolvedor />
      },
      {
        id: 2,
        title: "Usuários",
        content: <TabUsuariosClienteDesenvolvedor />
      }
      ]
    )
  }, [])
  return (
    <>
      <ReactTooltip />
      <Container fluid>
        <DommusBarraTitulo>
          <ItemBarraTitulo>
            Configurações de API
          </ItemBarraTitulo>
          <ItemBarraTitulo
            classe="btn"
            onClick={(e) => {abrirModalDesenvolvedor("Criar") }}
            data-tip={'Cadastrar Desenvolvedor'}
          >
            <FontAwesomeIcon
              icon={faPlus}
            />
          </ItemBarraTitulo>
        </DommusBarraTitulo>
        <section className="questionarios-section">
          <DommusTabs tabs={tabsSections} getActiveTabIndex={(index) => { setSelectedKey(index) }} goToTab={0} />
        </section>
      </Container>

      <DommusModal
        open={isDesenvolvedorModalOpen}
        close={handleFecharModal}
        titulo={tituloModalDesenvolvedor}
        size={"lg"}
        centered
        content={<CadastroDesenvolvedorAPI
          tipo={tipoModal}
          dev={desenvolvedor}
          fecharModal={handleFecharModal} />
        }>
      </DommusModal>
    </>
  )
}


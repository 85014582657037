import React, {useContext, useEffect, useState} from 'react';
import DommusBarraTitulo from "../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../components/DommusBarraTitulo/ItemBarraTitulo";
import {Row} from "react-bootstrap";
import './style.scss'
import guService from "../../services/guService";
import Swal from "sweetalert2";
import {trackPromise} from "react-promise-tracker";
import CardModulo from "./CardModulo";
import DommusLoading from "../../components/DommusLoading/DommusLoading";
import { ClienteContext } from '../../context/ClienteContext';

export default function Dashboard(props) {
    const [modulos, setModulos] = useState([]);
    const [listaModulos, setListaModulos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { atualizarModulos } = useContext(ClienteContext)
    const clientePadrao = guService.getLocalState('clientePadrao')

    useEffect(() => {
        const newModulos = modulos.map(modulo => {
            const response = modulo;
            if(modulo.modulo_usuario && modulo.modulo_usuario.status && modulo.modulo_usuario.cliente_modulo.status) {
                response.ativo = true;
            } else {
                response.ativo = false;
            }
            return response;
        })
        setListaModulos(newModulos);
        const modulosAtivos = newModulos.filter(modulo => modulo.ativo)
        let moduloAcessar = null
        if(clientePadrao) {
            moduloAcessar = modulosAtivos.find(modulo => modulo.id == 2)
        }
        if(modulosAtivos.length == 1 && !moduloAcessar) {
            moduloAcessar = modulosAtivos[0]
        }
        if(moduloAcessar && guService.getLocalState('rotaAnterior') == '/login') {
            guService.setLocalState('rotaAnterior', '/dashboard')
            guService.setLocalState('clientePadrao', '')
            guService.modulo.acessar(moduloAcessar.id).then(res => {
                if(Number(moduloAcessar.id) === 2){
                    window.location.href = `${res?.data?.modulo?.uri}:${guService.getLocalState('token')}`
                }else{
                    window.location.href = `${res.data.modulo.uri}${res.data.token}:${guService.getLocalState('token')}`
                }
            }).catch(error => {
                console.log(error)
                console.log(error?.response)
            })
        }
    }, [modulos])


    useEffect(() => {
        trackPromise(
          guService.dashboard.todosModulos().then(res => {
              setModulos(res.data);
          }).catch(e => {
              Swal.fire({
                  titleText: "Erro",
                  text: "Houve um problema ao buscar a listagem de módulos " + e,
                  icon: 'error'
              });
          })
        )
    }, [atualizarModulos]);

    return <>
        <DommusBarraTitulo>
            <ItemBarraTitulo>
                Acesso aos Módulos
            </ItemBarraTitulo>
        </DommusBarraTitulo>
        <Row className="dashboard">
            {listaModulos && modulos.map((modulo, index) => {
                return <CardModulo key={index} modulo={modulo}/>
            })}
        </Row>
        {isLoading && <DommusLoading/>}

    </>
}

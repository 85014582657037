import React, {useEffect, useMemo, useState} from 'react';
import DommusTabelaPaginada from "../../../../components/DommusTabelaPaginada";

import '../style.css'
import LinhaPerfil from "./LinhaPerfil";
import {trackPromise} from "react-promise-tracker";
import guService from "../../../../services/guService";
import Swal from "sweetalert2";

export default function ListaUsuarios({moduloSelecionadoComponente, handleEditarPerfil}) {

  useEffect(() => {
    if(moduloSelecionadoComponente){
      trackPromise(
        guService.perfil.listar(moduloSelecionadoComponente).then(res => {
          setTipoPerfil(res.data)
        }).catch(e =>
          Swal.fire({
            titleText: "Erro",
            text: "Houve um problema ao buscar lista de perfis " + e.response.data,
            icon: 'error'
          })
        )
      )
    }
  }, [moduloSelecionadoComponente])

  const [tiposPerfil, setTipoPerfil] = useState([]);

  const linhas = useMemo(() => {
    return tiposPerfil.map(perfil => <LinhaPerfil perfil={perfil} handleEditarPerfil={handleEditarPerfil} moduloSelecionado={moduloSelecionadoComponente}/>);
  }, [moduloSelecionadoComponente, tiposPerfil, handleEditarPerfil]);

  const thead = (
    <tr>
      <th class="col-1" >Nome</th>
      <th>Usuários</th>
      <th>Ativo</th>
      <th className="text-center" style={{ width: '10%' }}>Editar</th>
    </tr>
  );

  return (
    <>
      <DommusTabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas}
                            customClasses={'min-height-350'} />
    </>
  );
}

import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import guService from '../../services/guService';

export default function LoginVendas() {
  const { slugCliente } = useParams()
  const history = useHistory()

  useEffect(() => {
    if(slugCliente) {
      guService.setLocalState('clientePadrao', slugCliente)
      guService.setLocalState('rotaAnterior', '/login')
      history.push('/')
    }
  }, [slugCliente])

  return <>Aguarde...</>
}

import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { faEdit, faPenSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import DesenvolvedoresService from '../../../services/desenvolvedorService';
import CadastroDesenvolvedorAPI from '../CadastroDesenvolvedorAPI';
import { trackPromise } from 'react-promise-tracker';
import DommusToggle from '../../DommusToggle';
import DommusModal from '../../DommusModal';


export default function TabDesenvolvedor(props) {
    const [rows, setRows] = useState([]);
    const [isDesenvolvedorModalOpen, setIsDesenvolvedorModalOpen] = useState(false);
    const [tituloModalDesenvolvedor, setTituloModalDesenvolvedor] = useState();
    const [tipoModal, setTipoModal] = useState('Criar');
    const [desenvolvedor, setDesenvolvedor] = useState('Criar');

    const idUsuario = localStorage.getItem('@DommusGU-idUsuario');
    const usuariosConfigAPI = localStorage.getItem('@DommusGU-usuarios-config-api');
    const idCliente = localStorage.getItem('@DommusGU-idCliente');

    function buscarTodosDesenvolvedores() {
        trackPromise(DesenvolvedoresService.index({ id_cliente: idCliente }))
            .then((response) => {
                setRows(response.data);
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
            })
    }

    useEffect(() => {
        buscarTodosDesenvolvedores()
    }, [])


    function abrirModalDesenvolvedor(titulo, desenvolvedor = []) {
        setTituloModalDesenvolvedor(titulo + ' Desenvolvedor')
        setTipoModal(titulo)
        setDesenvolvedor(desenvolvedor)
        setIsDesenvolvedorModalOpen(true)
    }


    const handleFecharModal = () => {
        setIsDesenvolvedorModalOpen(false)
    }

    return (
        <>
            {rows?.length > 0 ?
                rows.map(linha => {
                    return (<Fragment key={linha.id}>
                        <Row>
                            <Col>
                                <DommusToggle title={linha.nome_desenvolvedor} toggle={false} >
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <label for="campo-nome-desenvolvedor">Nome:</label>
                                                <Form.Control id="campo-nome-desenvolvedor" value={linha.nome_desenvolvedor} disabled={true}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        {usuariosConfigAPI.includes(idUsuario) &&
                                            <Col xs={6}>
                                                <Form.Group>
                                                    <label for="campo-identificador">Chave De Identificação:</label>
                                                    <Form.Control id="campo-identificador" value={linha.identificador} disabled="true"></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        }
                                        <Col xs={1}>
                                            <label for="botao-editar-desenvolvedor">Editar:</label><br></br>
                                            <Button id="botao-editar-desenvolvedor" variant="primary" onClick={(e) => abrirModalDesenvolvedor("Editar", linha)}><FontAwesomeIcon icon={faPenSquare} /></Button>
                                        </Col>
                                    </Row>
                                </DommusToggle>
                            </Col>
                        </Row>
                    </Fragment>)
                }) : <Alert variant="warning">Nenhum desenvolvedor a ser exibido.</Alert>
            }



            <DommusModal
                open={isDesenvolvedorModalOpen}
                close={handleFecharModal}
                titulo={tituloModalDesenvolvedor}
                size={"lg"}
                centered
                content={<CadastroDesenvolvedorAPI
                    tipo={tipoModal}
                    dev={desenvolvedor}
                    fecharModal={handleFecharModal} />

                }>
            </DommusModal>
        </>
    )
}

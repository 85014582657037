import React, { useEffect, useState } from 'react';
import guService from '../../services/guService';
import '../Login/index.css';
import logo from '../../assets/logo.svg';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDice, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory, useParams} from "react-router-dom";
import {successToast} from "../../components/DommusToast";
import { trackPromise } from 'react-promise-tracker';

export default function AlterarSenha({ history }) {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [configSenhaForte, setConfigSenhaForte] = useState(null)
  const {token} = useParams();
  const browserHistory = useHistory();

  useEffect(() => {
    trackPromise(guService.configuracao.buscarPorChave('senha_aleatoria'))
      .then(response => setConfigSenhaForte(response?.data?.valor || null))
      .catch(error => {
        console.log(error)
        console.log(error?.response)
        Swal.fire('Ooops...', 'Houve um erro ao tentar buscar a configuração para redifinição de senha.', 'error')
      })
  }, [])

  function handleAlterarSubmit() {
    if(password !== passwordConfirmation) {
      setError(true);
      setErrorMessage('As senhas precisam ser iguais!');
      setPasswordConfirmation('');
      return;
    }
    guService.auth.alterarSenha(password, token).then(res => {
      successToast.fire(res.data.message)
      browserHistory.push('/login')
    }).catch(err => {
      Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao tentar recuperar a senha. Tente solicitar um novo link",
          icon: 'error'
      });
    })
  }

  function gerarSenhaAleatoria() {
    trackPromise(guService.auth.alterarSenhaComSenhaAleatoria(token))
      .then(res => {
        successToast.fire('Senha alterada com sucesso.')
        browserHistory.push('/login')
      }).catch(error => {
        console.log(error)
        console.log(error?.response)
        Swal.fire({
            titleText: 'Erro',
            text: 'Houve um problema ao gerar a nova senha.',
            icon: 'error'
        });
      })
  }

  function validarSenhaForte(event) {
    const senha = event.target.value
    let valida = true
    let mensagem = ''
    if(configSenhaForte) {
      if(configSenhaForte.maiusculas) {
        valida = /[A-Z]/.test(senha) 
        mensagem = 'A senha deve conter caracteres maiúsculos.'
      }
      if(configSenhaForte.minusculas && valida) {
        valida = /[a-z]/.test(senha) 
        mensagem = 'A senha deve conter caracteres minúsculos.'
      }
      if(configSenhaForte.numeros && valida) {
        valida = /[0-9]/.test(senha) 
        mensagem = 'A senha deve conter números.'
      }
      if(configSenhaForte.especiais && valida) {
        valida = /^(?=.*[@!#$%^&*()/\\])[@!#$%^&*()/\\a-zA-Z0-9]/.test(senha) 
        mensagem = 'A senha deve conter caracteres especiais.'
      }
      if(configSenhaForte.tamanho && valida) {
        valida = senha.length >= configSenhaForte.tamanho
        mensagem = `A senha deve conter no mínimo ${configSenhaForte.tamanho} caracteres.`
      }
    }
    if(valida) {
      setError(false)
      setErrorMessage('')
    } else {
      setError(true)
      setErrorMessage(mensagem)
    }
  }

  return (<div className="login">
    <div className="imagemLogin">
      <div className="imagemFundoLogin">
        <div className="logo-dommus">
          <img src={logo} alt="Dommus" />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="logo-dommus mobile">
        <img src={logo} alt="Dommus" />
      </div>
      <div className='faca-login'>Recuperação de Senha:</div>
      <div className="content">
        <form onSubmit={handleAlterarSubmit}>

          <label htmlFor="password">Nova Senha</label>
          <input
            type="password"
            id="password"
            placeholder="Nova Senha"
            required="required"
            value={password}
            onChange={event => setPassword(event.target.value)}
            onBlur={validarSenhaForte}
          />
          <label htmlFor="password_confirmation">Repita a Senha</label>
          <input
            type="password"
            id="password"
            required="required"
            placeholder="Repita a Senha"
            value={passwordConfirmation}
            onChange={event => setPasswordConfirmation(event.target.value)}
          />

          {error ? <p className="error"><FontAwesomeIcon icon={faExclamationTriangle}/> {errorMessage}</p> : <></>}

          <div className="buttons">
            <button className="btn btn-submit" onClick={handleAlterarSubmit} type="button">Alterar Senha</button>
          </div>
          <div className="buttons">
            <button className="btn btn-submit" onClick={gerarSenhaAleatoria} type="button" style={{ backgroundColor: 'var(--dommus-color-2)' }}>
              <FontAwesomeIcon icon={faDice}/> Gerar Senha Aleatória
            </button>
          </div>
          <div style={{marginBottom: '20px', textAlign: 'left'}} className="forgot-password">
            <Link to='/login'>
              <FontAwesomeIcon
                data-tip="recuperar senha"
                icon={faChevronLeft}
              /> Voltar para o login
            </Link>
          </div>

        </form>
      </div>

    </div>
  </div>
  )
}

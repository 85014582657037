import React from 'react';
import {Modal} from 'react-bootstrap';
import './index.scss';

export default function DommusModalSemHeader({children, open, className, close, size, title, content, itensHeader, ...rest}) {

	return (
		<Modal
			show={open}
			onHide={close}
			// dialogClassName="modal-90w"
			size={size || "lg"}
			{...rest}
			centered
			style={{
				zIndex: '1040',
				paddingRight: '17px',
				display: 'block'
			}}
			backdrop="static"
			className={`modal-sem-header ${className}`}
		>
			<Modal.Header closeButton className='header-section'>
				<div className='header-itens'>
					<span>{title}</span>
					{itensHeader}
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className={"conteudo"}>
					{content}
					{children}
				</div>
			</Modal.Body>
		</Modal>
	);
}

import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Col, Form, Row } from "react-bootstrap";
import DommusRequiredSpan from "../../../../../components/DommusRequiredSpan";
import "./style.scss";
import DommusToggle from '../../../../../components/DommusToggle'
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import PermissaoComponent from "../../../../../components/PermissaoComponent";
import guService from "../../../../../services/guService";
import SeletorModulo from "../../../SeletorModulo";
import SeletorModuloToggle from "../../../SeletorModulo/SeletorModuloToggle";
import Swal from "sweetalert2";
import {trackPromise} from "react-promise-tracker";
import {successToast} from "../../../../../components/DommusToast";
import StatusUsuarioModulo from "./StatusUsuarioModulo";
import EnviarConvite from "./EnviarConvite";

export default function ModalPerfil({closeModal, handleModuloSelecionado, moduloSelecionadoComponente, usuario}) {

  const [tiposPerfil, setTiposPerfil] = useState([])
  const [permissao, setPermissao] = useState([]);
  const [ativo, setAtivo] = useState(false);
  const [tipoPerfilSelecionado, setTipoPerfilSelecionado] = useState([]);
  const [buscaPermissaoInput, setBuscaPermissaoInput] = useState('');
  const [textoBtnSubmit, setTextoBtnSubmit] = useState('Salvar');
  const [seletorOpen, setSeletorOpen] = useState(true);
  const [moduloSelecionado, setModuloSelecionado] = useState(moduloSelecionadoComponente);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(usuario);
  const [canEditInfo, setCanEditInfo] = useState(true);

  const handleChangeModulo = useCallback(modulo => {
    setModuloSelecionado(modulo)
    handleModuloSelecionado(modulo);
  }, [handleModuloSelecionado]);

  const formPerfil = useRef();

  const { register, handleSubmit, watch, errors } = useForm();

  const handleSubmitForm = data => {

    const dataForm = {
      nome: data.nome ?? usuario.nome,
      email: data.email ?? usuario.email,
      ativo: ativo,
      modulos: {}
    }
    dataForm["modulos"][moduloSelecionado] = {
      id_perfil: tipoPerfilSelecionado
    }
    trackPromise(
      guService.usuario.gravarUsuario(dataForm).then(res => {
        successToast.fire({
          text: 'Usuário salvo com sucesso'
        })
      }).catch(e => {
          Swal.fire({
            titleText: "Erro",
            text: "Houve um problema ao buscar a  " + e,
            icon: 'error'
          });
        }
      ))
  }

  const handleErroModulo = useCallback(() => {
    setModuloSelecionado(null);
  }, []);

  const handleSelecionaPerfil = useCallback(id_perfil => {
    setTipoPerfilSelecionado(id_perfil);
  }, []);

  useEffect(() => {
    if(!moduloSelecionado) return;
    if(usuarioSelecionado && usuarioSelecionado.id && tiposPerfil) {
      const data = {
        id_usuario:usuarioSelecionado.id,
        id_modulo: moduloSelecionado
      }
      trackPromise(
        guService.usuario.buscaPerfilModulo(data).then(res => {
          setTipoPerfilSelecionado(res.data.id_usuario_tipo);
          setAtivo(res.data.status)
        }).catch(err => {
          Swal.fire({
            titleText: "Erro",
            text: "Houve um problema ao buscar o tipo de perfil  " + err,
            icon: 'error'
          });
        })
      )
    }
  }, [tiposPerfil, usuarioSelecionado, moduloSelecionado]);

  useEffect(() => {
    if(!moduloSelecionado) return;
    guService.perfil.listar(moduloSelecionado).then(res => {
      setTiposPerfil(res.data);
    }).catch(e => {
      Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os tipos de perfil  " + e.response?.data,
        icon: 'error'
      });
    })
  }, [moduloSelecionado]);

  useEffect(() => {
    if(usuario) {
      setCanEditInfo(false);
    }
  }, [usuario])


  return (
    <div id='modulos'>
      <div id='main'>
        <Form className="formCadastro" ref={formPerfil} onSubmit={handleSubmit(handleSubmitForm)}>
          <Row>

            <Form.Group as={Col} className="col-6" controlId="formNome">
              <Form.Label>Usuário<DommusRequiredSpan /></Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome do usuário"
                name="nome"
                disabled={!canEditInfo}
                defaultValue={usuarioSelecionado ? usuarioSelecionado.nome : ''}
                ref={register({required: "Campo Obrigatório."})}/>
              <div className="error" style={{color: 'red'}}>
                <ErrorMessage
                  errors={errors}
                  name="nome"
                />
              </div>
            </Form.Group>


            <Form.Group as={Col} className="col-6" controlId="formNome">
              <Form.Label>Email<DommusRequiredSpan /></Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o email do usuário"
                name="email"
                disabled={!canEditInfo}
                defaultValue={usuarioSelecionado ? usuarioSelecionado.email : ''}
                ref={register({
                  required: "Campo Obrigatório.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Preencha com um email válido",
                  },
                })}/>
              <div className="error" style={{color: 'red'}}>
                <ErrorMessage
                  errors={errors}
                  name="email"
                />
              </div>
            </Form.Group>

            <Form.Group as={Col} controlId="formNome">
              <Form.Label>Tipo de perfil</Form.Label>
              <Form.Control
                as="select"
                placeholder="Selecione"
                rows={3}
                name="tipo_perfil"
                disabled={!moduloSelecionado}
                value={tipoPerfilSelecionado}
                onChange={e => handleSelecionaPerfil(e.target.value)}
              >
                <option value="">Selecione o tipo de perfil</option>
                {tiposPerfil.map(tipoPerfil => {
                  return <option value={tipoPerfil.id_perfil} key={tipoPerfil.id_perfil}>{tipoPerfil.descricao}</option>
                })}
              </Form.Control>
              <div className="error" style={{color: 'red'}}>
                <ErrorMessage
                  errors={errors}
                  name="tipo_perfil"
                />
              </div>
            </Form.Group>
            <Col md={3}>
              <StatusUsuarioModulo
                ativo={ativo}
                habilitado={!!moduloSelecionado && usuario}
                moduloSelecionado={moduloSelecionado}
                usuario={usuario}
              />
            </Col>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formNome">
              <Form.Control
                type="text"
                placeholder="Buscar"
                value={buscaPermissaoInput}
                onChange={(event) => setBuscaPermissaoInput(event.target.value)} />
            </Form.Group>
          </Row>
          Permissoes:
          <PermissaoComponent
            permiteAlterar={false}
            buscaString={buscaPermissaoInput}
            handleSetPermissao={permissaoComponente => setPermissao(permissaoComponente)}
            moduloSelecionado={moduloSelecionado}
            tipoPerfilSelecionado={tipoPerfilSelecionado}
            handleErroModulo={handleErroModulo}
          />
          <Row>
            <Form.Group as={Col} controlId="formNome">

            </Form.Group>
          </Row>

          <Row className="btn-block">
            <Col md={3}>
              <button className="btn btn-danger" onClick={e => {
                e.preventDefault();
                closeModal()
              }}>
                Fechar
              </button>
            </Col>

            <Col md={3}>
              <button className={"btn btn-success" + (moduloSelecionado ? '' : ' disabled')}>
                <FontAwesomeIcon icon={faSave} />&nbsp; {textoBtnSubmit}
              </button>
            </Col>

            <Col md={5}>
              <EnviarConvite usuario={usuario}/>
            </Col>

            <Col md={1}>
              <SeletorModuloToggle handleChangeIsOpen={() => setSeletorOpen(!seletorOpen)} isOpen={seletorOpen}/>
            </Col>
          </Row>
        </Form>

      </div>
      <SeletorModulo isOpen={seletorOpen} handleChangeModulo={handleChangeModulo} moduloSelecionado={moduloSelecionado}/>
    </div>
  );
}

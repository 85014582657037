import React, { useEffect, useState } from 'react'
import { Alert, Button, Dropdown } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPenSquare } from '@fortawesome/free-solid-svg-icons';
import DesenvolvedoresService from '../../../services/desenvolvedorService';
import DommusTabelaPaginada from '../../DommusTabelaPaginada';
import DommusModal from '../../DommusModal';
import ConfigUsuarioAPI from '../ConfigUsuarioAPI';

export default function UsuariosDesenvolvedor({ id_cliente, id_modulo }) {
    const [rows, setRows] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [tipoModalAcessos, setTipoModalAcessos] = useState('Criar');
    const [usuario, setUsuario] = useState([]);

    function buscarUsuariosAcesso() {
        trackPromise(DesenvolvedoresService.usuariosComAcesso({ id_cliente: id_cliente, id_modulo: id_modulo }))
            .then((response) => {
                if (response.data?.length > 0) {
                    const linhas = response.data.map(linha => {
                        return (<>
                            <tr>
                                <th className="text-center">{linha.id}</th>
                                <th className="text-center">{linha.nome}</th>
                                <th className="text-center">{linha.email}</th>
                                <th className="text-center"> <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={
                                        (e) => abrirModal(linha)
                                        }>Editar</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown></th>
                            </tr>
                        </>)
                    })
                    setRows(linhas)
                }
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
            })
    }

    function fecharModalEditar() {
        setModalOpen(false)
    }
    function abrirModal(usuario) {
        setModalOpen(true)
        setUsuario(usuario)
        setTipoModalAcessos("Editar")
    }
    const thead = (
        <tr>
            <th className="text-center" style={{ width: '10%' }}>ID</th>
            <th>Nome</th>
            <th>Email</th>
            <th className="text-center" style={{ width: '10%' }}>Editar</th>
        </tr>
    );

    useEffect(() => {
        buscarUsuariosAcesso()
    }, [])
    return (
        <>
            <DommusTabelaPaginada
                linhasCabecalho={thead}
                optionsTabela={{}}
                linhasCorpo={rows}
                customClasses={'min-height-350'} />

            <DommusModal
                open={isModalOpen}
                close={(e) => setModalOpen(false)}
                titulo={"Usuário"}
                size={"lg"}
                centered
                content={<ConfigUsuarioAPI usuarioAcesso={usuario} modulo={id_modulo} tipo={tipoModalAcessos} fecharModal={fecharModalEditar} />}
            >
            </DommusModal>
        </>
    )
}

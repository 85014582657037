import React, {useCallback, useMemo, useState} from 'react';
import PermissaoAcao from "./PermissaoAcao";
import DommusCheckboxToggle from "../DommusCheckboxToggle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

export default function PermissaoArea({area,permiteAlterar,handleChangePermissaoTela}) {
    const handleChangePermissaoArea = useCallback(acao => {
        const indexAcao = area.acoes.findIndex(acaoArea => acaoArea.path === acao.path);
        area.acoes[indexAcao] = acao;
        handleChangePermissaoTela(area);
    }, [area, handleChangePermissaoTela])

    const [toggleLista, setToggleLista] = useState(true);
    const handleToggle = useCallback(e => {
        e.preventDefault();
        setToggleLista(value => !value);
    }, [])

    const areasRender = useMemo(() => {
        return area.acoes.map(acao => {
            return <PermissaoAcao
                key={acao.path}
                acao={acao}
                permiteAlterar={permiteAlterar}
                handleChangePermissaoArea={handleChangePermissaoArea}
            />
        })
    }, [area,permiteAlterar]);

    return (
        <li>
            <div className="info">
                <a href="#" onClick={(event) => handleToggle(event)} className="expandir">
                    {toggleLista ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
                </a>
                <div className="label">
                    {area.label}
                </div>
                <div className="toggle">
                    {/*<DommusCheckboxToggle/>*/}
                </div>
            </div>
        <ul className="acao">
            {toggleLista && areasRender}
        </ul>
        </li>
    );
}

import React, {useEffect, useMemo, useState} from 'react';
import DommusTabelaPaginada from "../../../../components/DommusTabelaPaginada";


import '../style.css'
import LinhaUsuario from "./LinhaUsuario";
import {trackPromise} from "react-promise-tracker";
import guService from "../../../../services/guService";
import Swal from "sweetalert2";

export default function ListaUsuarios({moduloSelecionadoComponente, handleEditarUsuario}) {

  const [listaUsuarios, setListaUsuarios] = useState([]);


  useEffect(() => {
      trackPromise(
        guService.usuario.listaCliente(moduloSelecionadoComponente).then(res => {
          setListaUsuarios(res.data)
        }).catch(e =>
          Swal.fire({
            titleText: "Erro",
            text: "Houve um problema ao buscar lista de perfis " + e.response.data,
            icon: 'error'
          })
        )
      )
  }, [moduloSelecionadoComponente])


  const linhas = useMemo(() => {
    return listaUsuarios.map(clienteUsuario => <LinhaUsuario clienteUsuario={clienteUsuario} handleEditarUsuario={handleEditarUsuario}/>)
  }, [listaUsuarios, handleEditarUsuario])

  const thead = (
      <tr>
        <th className="col-4">Nome</th>
        <th>Email</th>
        <th>Ativo</th>
        <th>Editar</th>
      </tr>
  );

  return (
      <>
        <DommusTabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas}
                              customClasses={'min-height-350'} />
      </>
  );
}

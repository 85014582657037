import React, {useCallback, useEffect, useMemo, useState} from 'react';

import './style.scss'

import PermissaoTela from "./PermissaoTela";
import guService from "../../services/guService";
import Swal from "sweetalert2";
import {trackPromise} from "react-promise-tracker";

export default function PermissaoComponent ({
                                                permiteAlterar,
                                                handleSetPermissao,
                                                buscaString,
                                                moduloSelecionado,
                                                handleErroModulo,
                                                tipoPerfilSelecionado
                                            }) {

    const handleChangeToggle = function(){};
    const [permissoes, setPermissoes] = useState([]);
    const [listaPermissoes, setListaPermissoes] = useState([]);

    const handleChangePermissao = useCallback((tela) => {
        const indexAcao = permissoes.findIndex(telaPermissao => telaPermissao.path === tela.path);
        permissoes[indexAcao] = tela;
        handleSetPermissao(permissoes);
    }, [permissoes, handleSetPermissao]);

    //todo melhorar filtro buscar por ação
    const filtraPermissoes = useCallback(buscaString => {
        let searchString = buscaString.toLowerCase();
        return permissoes.filter(function search(row) {
            return Object.keys(row).some((key) => {
                if(typeof row[key] === "string") {
                    return row[key].toLowerCase().indexOf(searchString) > -1;
                } else if(row[key] && typeof row[key] === "object") {
                    return search(row[key]);
                }
                return false;
            });
        });
    }, [permissoes]);

    useEffect(() => {
        if(buscaString && buscaString.length > 0) {
            setListaPermissoes(filtraPermissoes(buscaString));
        } else {
            setListaPermissoes(permissoes);
        }
    }, [buscaString, filtraPermissoes, permissoes])


    useEffect(() => {
        if(!tipoPerfilSelecionado || !moduloSelecionado) {
            setPermissoes([]);
            setListaPermissoes([]);
            return;
        }

        const data = {
            "id_tipo":tipoPerfilSelecionado,
            "id_cliente": guService.getLocalState('idCliente'),
            "id_modulo": moduloSelecionado
        };
        setPermissoes([]);
        trackPromise(
          guService.permissao.buscaPermissao(data).then(res => {
              setPermissoes(res.data.permissoes);
              setListaPermissoes(res.data.permissoes);
              handleSetPermissao(res.data.permissoes);
          }).catch(e => {
              setPermissoes([]);
              handleErroModulo();
              Swal.fire({
                  titleText: "Erro",
                  text: "Houve um problema ao buscar aa buscar lista de permissões: " + e.response.data,
                  icon: 'error'
              });
          }))

    }, [moduloSelecionado, handleErroModulo, tipoPerfilSelecionado]);

    const permissoesRender = useMemo(() => {
        if(listaPermissoes && listaPermissoes.length) {
            return listaPermissoes.map(tela => {
                return <PermissaoTela
                  key={tela.path}
                  tela={tela}
                  permiteAlterar={permiteAlterar}
                  handleChangePermissao={handleChangePermissao}
                />
            })
        }
    }, [listaPermissoes, permiteAlterar, handleChangePermissao]);

    return (
      <div className="wrapper-permissoes">
          <hr/>
          {permissoesRender}
      </div>
    );
}

import api from "./api";

const DesenvolvedoresService = {
    index: (filtros) => {
        return api.get("desenvolvedor", { params: filtros });
    },
    show: (filtros) => {
        return api.get("desenvolvedor", { params: filtros });
    },
    salvar: (desenvolvedor) => {
        return api.post("desenvolvedor", desenvolvedor);
    },
    atualizar: (desenvolvedor) => {
        return api.put(`desenvolvedor/${desenvolvedor.id}`, desenvolvedor);
    },
    excluir: (idDesenvolvedor) => {
        return api.delete(`desenvolvedor/${idDesenvolvedor}`);
    },
    usuariosComAcesso: (filtros) => {
        return api.get("desenvolvedor/usuarios", { params: filtros });
    },
    buscarRecursos: (filtros) => {
        return api.get("desenvolvedor/recursos", { params: filtros });
    },
    buscarMetodos: (filtros) => {
        return api.get("desenvolvedor/metodos", { params: filtros });
    },
    buscarUsuarios: (filtros) => {
        return api.get("desenvolvedor/usuarios-cliente", { params: filtros });
    },
    atribuirDesenvolvedor: (conteudo) => {
        return api.post("desenvolvedor/atribuir", { atribuir: conteudo });
    },
    salvarAcessos: (conteudo) => {
        return api.post("desenvolvedor/usuario/acessos", conteudo );
    },
    atualizarAcessos: (conteudo) => {
        return api.put("desenvolvedor/usuario/acessos", { acesso: conteudo });
    },
    buscarModulosDesenvolvedor: (filtros) => {
        return api.get("desenvolvedor/modulos", { params: filtros });
    },     
};

export default DesenvolvedoresService;
